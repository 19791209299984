import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import BackgroundBase from './BackgroundBase'
import heartFade from '../images/heart-fade.svg';
import heartRight from '../images/heart-side-right.svg';

const BackgroundHeart = styled(BackgroundBase)`
    ${media.sm`
      background-repeat: no-repeat;
      background-position: center 100px;
      background-image: url(${heartFade});
    `}

    &::before {
        content: '';
        background-image: url(${heartRight});
        background-position: 0 ${rem(50)};
        background-repeat: no-repeat;
        position: absolute;
        top: ${rem(50)};
        left: 0;
        height: 75%;
        width: 100%;
        z-index: 0;

        ${media.sm`
          content: unset;
        `}
    }
`;

export default BackgroundHeart