import styled, {css} from 'styled-components';
import {media} from 'baseline-ui/layout';

const CardsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    ${({ blockWidth }) =>
        css`
            width: 100%;
            ${media.md`
                 width: ${blockWidth}%;
            `};
        `
     }
    ${media.md`
         flex-direction: row;
    `};
`
export default CardsWrapper;