import styled from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const SlideWrapper = styled.div`
    position: relative;
    background-color: ${({ theme }) => theme.site.welcome.bgEnd};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    justify-content:  ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
    height: 100%;
    text-align: left;
    padding-top: ${rem(32)};
    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
   `};
`;
export default SlideWrapper;