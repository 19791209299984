import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import BackgroundBase from './BackgroundBase'
import heartRight from '../images/heart-side-right.svg';
import heartLeft from '../images/heart-side-left.svg';

const BackgroundHeartSideSpaceBetween = styled(BackgroundBase)`
  &::before {
      content: '';
      background-repeat: no-repeat;
      background-position: 0 ${rem(50)};
      position: absolute;
      background-image: url(${heartRight});
      top: ${rem(50)};
      left: 0;
      height: 75%;
      width: 100%;
      z-index: 0;
      ${media.sm`
        width: 50%;
      `}
  }

  &::after {
      content: unset;
      background-repeat: no-repeat;
      background-position: 100% ${rem(50)};
      background-image: url(${heartLeft});
      position: absolute;
      top: ${rem(50)};
      right: 0;
      width: 50%;
      height: 75%;
      z-index: 0;
      ${media.sm`
        content: '';
        background-image: url(${heartLeft});
      `}
  }
`;

export default BackgroundHeartSideSpaceBetween