import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const FieldFlexWrapper = styled.div`
    display: flex; 
    flex-direction: column;
     > * {
        flex-grow: 1;
        width: 100%;
      }
    ${media.sm`
         flex-direction: row;
         column-gap: ${rem(16)};
    `};
`;

export default FieldFlexWrapper;