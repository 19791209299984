import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const ListWrapper = styled.div`
    margin-top: ${rem(8)};
    margin-bottom: ${rem(16)};
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(3, 1fr); 
    flex: 1 1 40%;
    grid-row-gap: ${rem(8)};
    font-size: ${rem(16)};
    ${media.md`
        margin-top: ${rem(0)};
        grid-template-columns: repeat(3, 1fr); 
        grid-template-rows: repeat(2, 1fr);
        padding-top: ${rem(8)};
        padding-left: ${rem(16)};
    `};
`;

export default ListWrapper;