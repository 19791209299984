import { rem } from 'polished';
import { get } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import formTheme from '../styles/formTheme';
import { media } from '../../../layout';
import { FLEX_DIRECTION } from 'common/consts';

const FieldsGrouped = styled.div`
    display: flex;
    flex-direction: ${FLEX_DIRECTION.COLUMN};
    margin: 0 -${({ theme }) => rem(get(theme, 'form.fieldsGrouped.marginH', formTheme.form.fieldsGrouped.marginH))};

    ${({ direction_xxs }) => {
        if (direction_xxs) {
            return media.xxs`
                        flex-direction: ${direction_xxs};
                    `;
        }
    }}

    ${({ direction_xs }) => {
        if (direction_xs) {
            return media.xs`
                        flex-direction: ${direction_xs};
                    `;
        }
    }}

    ${({ direction_sm }) => {
        if (direction_sm) {
            return media.sm`
                        flex-direction: ${direction_sm};
                    `;
        }
    }}

    ${({ direction_md }) => {
        if (direction_md) {
            return media.md`
                        flex-direction: ${direction_md};
                    `;
        }
    }}

    ${({ direction_lg }) => {
        if (direction_lg) {
            return media.lg`
                        flex-direction: ${direction_lg};
                    `;
        }
    }}

    ${({ direction_xl }) => {
        if (direction_xl) {
            return media.xl`
                        flex-direction: ${direction_xl};
                    `;
        }
    }}
`;

FieldsGrouped.propTypes = {
    defaultDirection: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_xxs: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_xs: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_sm: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_md: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_lg: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
    direction_xl: PropTypes.oneOf[FLEX_DIRECTION.ROW, FLEX_DIRECTION.COLUMN],
};

export default FieldsGrouped;
