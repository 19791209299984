import React from 'react';
import Badge, { badgeStyleProps } from './Badge';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types'
import { has } from 'lodash';
import { typographyUtils } from 'baseline-ui/typography';

const BadgeList = ({ items = [], badgeStyle, ...props }) => {
    return (
        <BadgeListStyled {...props}>
            {items.map((badge) => (
                <Badge
                    {...badgeStyle}
                    icon={badge.icon}
                    title={badge.title}
                />
            ))}
        </BadgeListStyled>
    );
};

const BadgeListStyled = styled.div`
    align-items: ${(props) => has(props, 'alignItems') ? props.alignItems : 'flex-start'};
    display: ${(props) => has(props, 'display') ? props.display : 'block'};
    justify-content: ${(props) => has(props, 'justifyContent') ? props.justifyContent : 'flex-start'};
    flex-direction: ${(props) => has(props, 'flexDirection') ? props.flexDirection : 'row'};
    gap: ${(props) => (has(props, 'gap') ? rem(props.gap) : 0)};
    margin-top: ${({ margin }) => has(margin, 'top') ? typographyUtils.processSize(margin.top) : 0}};
    margin-right: ${({ margin }) => has(margin, 'right') ? typographyUtils.processSize(margin.right) : 0}};
    margin-bottom: ${({ margin }) => has(margin, 'bottom') ? typographyUtils.processSize(margin.bottom) : 0}};
    margin-left: ${({ margin }) => has(margin, 'left') ? typographyUtils.processSize(margin.left) : 0}};
    padding-top: ${({ padding }) => has(padding, 'top') ? typographyUtils.processSize(padding.top) : 0 }};
    padding-right: ${({ padding }) => has(padding, 'right') ? typographyUtils.processSize(padding.right) : 0 }};
    padding-bottom: ${({ padding }) => has(padding, 'bottom') ? typographyUtils.processSize(padding.bottom) : 0 }};
    padding-left: ${({ padding }) => has(padding, 'left') ? typographyUtils.processSize(padding.left) : 0 }};
    ${(props) => has(props, 'textAlign') && css`text-align: ${props.textAlign};`}
    ${(props) => has(props, 'width') && css`width: ${typographyUtils.processSize(props.width)};`}
    ${(props) => has(props, 'maxWidth') && css`max-width: ${typographyUtils.processSize(props.maxWidth)};`}
`;

BadgeList.PropTypes = {
  items: PropTypes.arrayOf({
    icon: PropTypes.node,
    title: PropTypes.string
  }),
  display: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  gap: PropTypes.number,
  margin: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  padding: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  badgeStyle: badgeStyleProps
}

export default BadgeList;
