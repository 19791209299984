import siteBaseTheme from './siteBaseTheme';

const siteButtonTheme = {
    button: {
        primary: {
            background: siteBaseTheme.base.colors.primary,
            border: siteBaseTheme.base.colors.primary,
            color: siteBaseTheme.base.colors.light,
        },
        primaryMuted: {
            background: siteBaseTheme.base.colors.primaryMuted,
            border: siteBaseTheme.base.colors.primaryMuted,
            color: siteBaseTheme.base.colors.primary,
        },
        secondary: {
            background: siteBaseTheme.base.colors.secondary,
            border: siteBaseTheme.base.colors.secondary,
            color: siteBaseTheme.base.colors.light,
        },
        info: {
            background: siteBaseTheme.base.colors.infoLight,
            border: siteBaseTheme.base.colors.infoLight,
            color: siteBaseTheme.base.colors.info,
        },
        download: {
            background: siteBaseTheme.base.colors.blueLight,
            border: siteBaseTheme.base.colors.blueLight,
            color: siteBaseTheme.base.colors.light,
        },
        primaryDisabled: {
            background: siteBaseTheme.base.grays.grayLightest,
            border: siteBaseTheme.base.grays.grayLightest,
            color: siteBaseTheme.base.grays.gray,
        },
        muted: {
            background: siteBaseTheme.base.colors.muted,
            border: siteBaseTheme.base.colors.muted,
            color: siteBaseTheme.base.colors.light,
        },
        fontWeight: 400,
        lineHeight: 1.25,
        iconSize: {
            default: 18,
            sm: 14,
            md: 20,
            // lg: 22,
            // xl: 28,
        },
        fontSize: {
            default: 14,
            sm: 12,
            md: 16,
            lg: 18,
            xl: 24,
        },
        borderRadius: {
            default: 22,
            sm: 22,
            md: 24,
            lg: 22,
            xl: 22,
        },
        padding: {
            default: {
                h: 18,
                v: 12,
            },
            sm: {
                h: 12,
                v: 8,
            },
            md: {
                h: 18,
                v: 12,
            },
            lg: {
                h: 20,
                v: 14,
            },
            xl: {
                h: 24,
                v: 16,
            },
        },
    },
};

export default siteButtonTheme;
