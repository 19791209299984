const LOGIN_PATH = '/Login';
const SIGNUP_PATH = '/Signup';
const HOME_PATH = '/';
const BUSINESS_PATH = '/Businesses';
const ABOUT_PATH = '/About';
const UPDATES_PATH = '/Updates';
const JOIN_BETA_PATH = '/JoinBeta';
const MY_FAVEPAGE_PATH = '/MyFavePage';
const INQUIRE_PATH = '/Inquiry';
const ACCOUNT_PATH = '/Account';
const ADD_BUSINESS_PATH = '/AddBusiness';
const ADD_FAVEPAGE_PATH = '/AddFavePage';
const EDIT_BUSINESS_PATH = '/EditBusiness';
const EDIT_FAVEPAGE_PATH = '/EditFavePage';
const REFER_A_FRIEND_PATH = '/ReferAFriend';
const CREATE_FAVEPAGE = '/CreateFavePage';
const FORGOT_PASSWORD_PATH = '/ForgotPassword';
const TERMS_AND_CONDITIONS_PATH = '/TermsAndConditions';
const ADMIN_AREA_PATH = '/adminarea';
const TAG_MANAGER_PATH = `${ADMIN_AREA_PATH}/tags`;
const CATEGORYMANAGER_PATH = `${ADMIN_AREA_PATH}/categories`;
const ENTITY_MAPPING_CONFIGURATION = `${ADMIN_AREA_PATH}/entity-mapping-configuration`;
const BETA_SIGNUPS = `${ADMIN_AREA_PATH}/betasignups`;
const SYSTEM_PUSH = `${ADMIN_AREA_PATH}/system-push`;
const LINK_NOTIFICATIONS = '/link-notifications';

export {
    LOGIN_PATH,
    SIGNUP_PATH,
    HOME_PATH,
    JOIN_BETA_PATH,
    MY_FAVEPAGE_PATH,
    CATEGORYMANAGER_PATH,
    TAG_MANAGER_PATH,
    INQUIRE_PATH,
    ACCOUNT_PATH,
    ADD_BUSINESS_PATH,
    ADD_FAVEPAGE_PATH,
    EDIT_BUSINESS_PATH,
    EDIT_FAVEPAGE_PATH,
    ADMIN_AREA_PATH,
    BETA_SIGNUPS,
    REFER_A_FRIEND_PATH,
    CREATE_FAVEPAGE,
    FORGOT_PASSWORD_PATH,
    TERMS_AND_CONDITIONS_PATH,
    SYSTEM_PUSH,
    LINK_NOTIFICATIONS,
    BUSINESS_PATH,
    ABOUT_PATH,
    UPDATES_PATH,
    ENTITY_MAPPING_CONFIGURATION,
};
