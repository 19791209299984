import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import siteBaseTheme from 'styles/siteBaseTheme';

const LaunchWrapper = styled.div`
    background-color: ${({ theme, bgWhite }) => bgWhite ? theme.site.welcome.bgEnd : theme.site.welcome.bgLight};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    position: relative;
    padding-bottom: ${rem(40)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    position: relative;
 
    border-radius: ${({borderRadius}) =>
        borderRadius
            ? `${rem(borderRadius)}`
            : 'unset'
    };
    border-top: ${({borderTop}) =>
    borderTop
        ? `14px solid ${siteBaseTheme.base.colors.primary}`
        : '0'
    };
    ${({ afterImage, width }) =>
    afterImage &&
    css`
            &::after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 0;
                bottom: ${rem(-40)};
                background-image: url(${afterImage});
                width: ${width ? `${rem(width - 100)}` : '100%'};
                height: ${width ? `${rem(width - 100)}` : '100%'};
                ${media.md`
                    background-image: url(${afterImage});
                    width: ${width ? `${rem(width)}` : '100%'};
                    height: ${width ? `${rem(width)}` : '100%'};
                    bottom: ${rem(-56)};
                `}
            }
        `}
        
    ${({ beforeImage, width  }) =>
    beforeImage &&
    css`
            &::before {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: ${rem(-36)};
                top: 0;
                width: ${width ? `${rem(width - 100)}` : '100%'};
                height: ${width ? `${rem(width - 100)}` : '100%'};
                transform: rotate(90deg);
                background-image: url(${beforeImage});
                ${media.md`
                    width: ${width ? `${rem(width)}` : '100%'};
                    height: ${width ? `${rem(width)}` : '100%'};
                    background-image: url(${beforeImage});
                    left: ${rem(0)};
                    top: ${rem(32)};
                    transform: rotate(0deg);
                `}
            }
        `}
    ${({ width, type, image}) =>
        type==='middle' &&
        css`
                &::before {
                    content: '';
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    ${media.md`
                        background-image: url(${image});
                        width: ${width ? `${rem(width)}` : '100%'};
                        height: ${width ? `${rem(width)}` : '100%'};
                        background-image: url(${image});
                        top: ${rem(-105.6)};
                    `}
                }
            `}
    ${media.md`
        padding-left: ${rem(32)};
        padding-right: ${rem(32)};
        padding-bottom: ${rem(12)};
        padding-top: ${rem(32)};
        margin-top: ${rem(40)};
        margin-bottom: ${({marginBottom}) =>
                            marginBottom
                                ? `${rem(marginBottom)}`
                                : 'unset'
                        };
    `};
`;

export default LaunchWrapper;
