import CommonWrapper from 'screens/CommonWrapper';
import React from 'react';
import Background from 'baseline-ui/background';
import { BACKGROUND_MODES } from 'common/consts';
import Intro from './Intro';
import HelpSection from './HelpSection';
import PreferSection from './PreferSection';

const BusinessAndProfessionalsScreen = () => {
    return (
        <CommonWrapper>
            <Background mode={BACKGROUND_MODES.TRIANGLE_FADE_SPACE_BETWEEN}>
                <Intro />
            </Background>
            <Background mode={BACKGROUND_MODES.TRIANGLE_SIDES_MIDDLE_GRADIENT_BOTTOM}>
                <HelpSection />
                <PreferSection />
            </Background>
        </CommonWrapper>
    );
};

export default BusinessAndProfessionalsScreen;
