import {
    RESET_DOWNLOAD_APP_FORM_DATA_START,
    SET_DOWNLOAD_APP_FORM_DATA,
} from '../actions/DownloadFavePageAppFormActions';

const DEFAULT_STATE = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    zipCode: '',
    device: '',
    accountEmailAddress: '',
    isLastSlid: false,
};

const DownloadFavePageAppFormReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DOWNLOAD_APP_FORM_DATA: {
            return {
                ...state,
                ...payload,
            };
        }

        case RESET_DOWNLOAD_APP_FORM_DATA_START: {
            return {
                ...DEFAULT_STATE,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default DownloadFavePageAppFormReducer;
