import React from 'react';
import MobileView from './MobileView';
import TabletDesktopView from './TabletDesktopView';

export default function HelpSection() {
    return (
        <>
            <MobileView />
            <TabletDesktopView />
        </>
    );
}
