import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    POST_PROMOTION_NOTIFICATION_PROCESS,
    POST_PROMOTION_NOTIFICATION_START,
} from '../../../redux/actions/PushActions';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { Col, Row } from 'baseline-ui/layout';
import {
    FieldContainer,
    FormCheckbox,
    FormInput,
    FormRadio,
    FormRadioGroup,
    FormSelect,
    FormTextarea,
} from 'baseline-ui/form';
import { Button } from 'baseline-ui/button';
import { Modal } from 'baseline-ui/modal';
import { H2, H4, P } from 'baseline-ui/typography';
import PhotoListItem from 'components/photoList/PhotoListItem';
import PromotionalNotificationsHistory from 'components/PromotionalNotificationsHistory';
import useProcessComplete from 'utils/useProcessComplete';
import { GET_BUSINESS_PROCESS, GET_BUSINESS_START } from 'redux/actions/OwnABussinessActions';
import { GET_SECTION_PRODUCTS_PROCESS } from 'redux/actions/SectionActions';
import useProcessError from 'utils/useProcessError';
import { FeedbackContext } from 'baseline-ui/feedback';

const BusinessPromotionalPush = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [showConfrimation, setShowConfirmation] = useState(false);
    const schema = Yup.object({
        pushMessageTitle: Yup.string().max(150).required('Required'),
    });
    const business = useSelector(businessFormSelector);
    const businessProducts = useSelector(
        ({ SectionReducer }) => SectionReducer.sectionProducts?.['undefined'] ?? [],
    );
    const { photos: businessPhotos = [], adminOnly } = useSelector(
        ({ BusinessFormReducer }) => BusinessFormReducer,
    );

    const [photos, setPhotos] = useState([...businessPhotos]);

    const options = useMemo(() => {
        return [
            {
                value: business?._id,
                label: business?.name,
            },
        ].concat(
            businessProducts?.map((product) => ({
                value: product._id,
                label: product.name,
            })),
        );
    }, [businessProducts]);

    const audienceOptions = useMemo(() => {
        return [
            {
                value: [business._id].concat(businessProducts.map((p) => p._id)),
                label: 'All',
            },
            {
                value: business?._id,
                label: business?.name,
            },
        ].concat(
            businessProducts?.map((product) => ({
                value: product._id,
                label: product.name,
            })),
        );
    }, [businessProducts]);

    const isProductcsLoading = useProcessComplete({
        processNames: [GET_BUSINESS_PROCESS, GET_SECTION_PRODUCTS_PROCESS],
    });
    const { setFeedbackOnStage } = useContext(FeedbackContext);
    const isError = useProcessError({
        processNames: [POST_PROMOTION_NOTIFICATION_PROCESS],
        withFeedback: true,
    });
    const isComplete = useProcessComplete({
        processNames: [POST_PROMOTION_NOTIFICATION_PROCESS],
        withFeedback: true,
    });

    useEffect(() => {
        if (isComplete) {
            setFeedbackOnStage({
                label: 'Notification sent',
                type: 'success',
                show: true,
                timeout: 3200,
            });
        }
    }, [isError, isComplete]);

    if (!adminOnly.maxNotificationsPerWeek || adminOnly.maxNotificationsPerWeek === '0') {
        return (
            <H4>
                <FormattedMessage id="BUSINESS.page.sections.pushNotifications.notAvailable" />
            </H4>
        );
    }

    return (
        <>
            <Formik
                initialValues={{
                    pushMessage: '',
                    pushMessageTitle: '',
                    audienceEntityId: business?._id,
                    entityId: business?._id,
                    sendToSaves: true,
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    console.log(values);
                    dispatch({
                        type: POST_PROMOTION_NOTIFICATION_START,
                        payload: {
                            ...values,
                            sendingEntityId: business._id,
                        },
                    });
                    actions.resetForm();
                }}
            >
                {(props) => (
                    <Form noValidate>
                        {showConfrimation && (
                            <Modal
                                title="Confirm"
                                isOpen={true}
                                isDismissible={true}
                                handleOnDismiss={() => setShowConfirmation(false)}
                                dialogClassName="modal-90w"
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                footerActions={[
                                    {
                                        label: intl.formatMessage({
                                            id: 'BUSINESS.sections.pushNotifications.modal.cancel.button.label',
                                        }),
                                        btnType: 'link',
                                        skin: 'muted',
                                        onClick: () => {
                                            setShowConfirmation(false);
                                        },
                                    },
                                    {
                                        label: intl.formatMessage({
                                            id: 'BUSINESS.sections.pushNotifications.modal.confirm.button.label',
                                        }),
                                        onClick: () => {
                                            props.handleSubmit();
                                            setShowConfirmation(false);
                                        },
                                    },
                                ]}
                            >
                                <P>
                                    <FormattedMessage id="BUSINESS.sections.pushNotifications.modal.body" />
                                </P>
                            </Modal>
                        )}
                        <Row>
                            <Col xxs={30} xs={30} sm={30} md={30} xl={30}>
                                <FieldContainer>
                                    <Field
                                        customError={props.errors.pushMessageTitle}
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: `BUSINESS.page.sections.pushNotifications.form.title.label`,
                                        })}
                                        name="pushMessageTitle"
                                        id="pushMessageTitle"
                                        rows={16}
                                    />
                                </FieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxs={30} xs={30} sm={30} md={15} xl={15}>
                                <FieldContainer>
                                    <Field
                                        label="Select Audience"
                                        component={FormSelect}
                                        options={audienceOptions}
                                        name="audienceEntityId"
                                        id="audienceEntityId"
                                        translateOptionsLabel={false}
                                    />
                                </FieldContainer>
                            </Col>
                            <Col xxs={30} xs={30} sm={30} md={15} xl={15}>
                                <FieldContainer>
                                    <Field
                                        label="Select Target"
                                        component={FormSelect}
                                        options={options}
                                        name="entityId"
                                        id="entityId"
                                        translateOptionsLabel={false}
                                        onChange={(e) => {
                                            const entityId = e.target.value;
                                            const photos =
                                                entityId === business._id
                                                    ? businessPhotos
                                                    : businessProducts.find(
                                                          (p) => p._id === entityId,
                                                      )?.photos;
                                            setPhotos(photos);
                                            console.log(e.target.value);
                                            props.setFieldValue('entityId', e.target.value);
                                        }}
                                    />
                                </FieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxs={30}>
                                <FieldContainer>
                                    <Field
                                        component={FormCheckbox}
                                        name="sendToSaves"
                                        id="sendToSaves"
                                    >
                                        <FormattedMessage id="BUSINESS.page.sections.pushNotifications.form.sendToSaves.checkbox.label" />
                                    </Field>
                                </FieldContainer>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxs={30}>
                                <FieldContainer>
                                    <Field
                                        component={FormTextarea}
                                        label={intl.formatMessage({
                                            id: `BUSINESS.page.sections.pushNotifications.form.body.label`,
                                        })}
                                        name="pushMessage"
                                        id="pushMessage"
                                        rows={5}
                                    />
                                </FieldContainer>
                            </Col>
                        </Row>

                        <Field component={FormRadioGroup} id="radioInput" name="imageUrl">
                            <Row>
                                <Col>
                                    <Field
                                        component={FormRadio}
                                        id={'noImage'}
                                        name={'imageUrl'}
                                        value={undefined}
                                    >
                                        No Image
                                    </Field>
                                </Col>
                                {photos?.map((photo, idx) => (
                                    <Col key={`photos_${photo.name}`}>
                                        <PhotoListItem
                                            image={photo.url}
                                            radioId={photo.name}
                                            radioName="imageUrl"
                                            radioValue={photo.url}
                                            radioLabel={intl.formatMessage({
                                                id: `BUSINESS.page.sections.pushNotifications.form.radio.label`,
                                            })}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Field>

                        <Row justifyContent={'right'}>
                            <Button
                                onClick={async () => {
                                    const errors = await props.validateForm();
                                    if (!errors.pushMessageTitle) {
                                        setShowConfirmation(true);
                                    }
                                }}
                            >
                                <FormattedMessage id="BUSINESS.sections.pushNotifications.modal.confirm.button.label" />
                            </Button>
                        </Row>
                    </Form>
                )}
            </Formik>
            <Row>
                <H2>
                    <FormattedMessage id="BUSINESS.page.sections.pushNotifications.history.header" />
                </H2>
            </Row>
            {!isProductcsLoading && (
                <PromotionalNotificationsHistory
                    businessId={business._id}
                    entityIds={options.map((o) => o.value)}
                />
            )}
        </>
    );
};

export default BusinessPromotionalPush;
