import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'baseline-ui/button';
import { EntityPicker } from 'components/EntityPicker';
import { useFormikContext } from 'formik';
import { H3 } from 'baseline-ui/typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL, SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET } from 'redux/actions/EntityLinkActions';
import {ENTITY_LINK_STATUS_APPROVED} from 'common/entityLinkStatus';

import { ButtonArea } from '../ButtonArea';

export const initialValues = {
    entitypicker_search: '',
    entitypicker_selected: '',
};

export const EntityLinkAdminForm = ({entityType} = props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [entity, setEntity] = useState(null);
    const businessForm = useSelector(businessFormSelector);
    const { sendAndApproveEntityLinkRequestState: { error, success, inprogress } } = useSelector(({ EntityLinkReducer }) => EntityLinkReducer);
    const { resetForm } = useFormikContext();
    let statusMessage = '';
    let statusType = '';

    if (error) {
        statusMessage = intl.formatMessage({
            id: `addBusiness.page.sections.requestToLink.error.${error ?? 'default'}`,
        });
        statusType = 'error';
    } else if (success) {
        statusMessage = intl.formatMessage({
            id: `addBusiness.page.sections.requestToLink.success`,
        });
        statusType = 'success';
    }
    const disableSubmit = !!(!entity || error || success);
    const onSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        if (disableSubmit) {
            return;
        }
        const payload = {
            approvalstatus: ENTITY_LINK_STATUS_APPROVED,
            message: 'Added & Approved by Super Admin.',
            entityId: businessForm._id,
            requestingEntityId: entity._id,
            name: entity.name,
            firstName: entity.firstName || entity.ownerFirstName,
            lastName: entity.lastName || entity.ownerLastName,
            position: entity.position,
            entityRequestedType: entityType
        };
        dispatch({ type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL, payload });
    };
    const onReset = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        setEntity(null);
        resetForm(initialValues);
        dispatch({ type: SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET });
    };
    const hideControls = !!statusMessage;
    // TODO: Style status message. Currently being stored in data-status on the message element.
    return (
        <>
            {statusMessage && <H3 data-status={statusType}>{statusMessage}</H3>}
            <EntityPicker
                identifier="peopleLinkToBusinessPicker"
                hidden={hideControls}
                label={`addBusiness.page.sections.${entityType}.superadmin.addandapprove.label`}
                type={entityType}
                onChange={(ent) => setEntity(ent)}
            />
            <ButtonArea>
                <Button type="button" skin="primary" onClick={onReset} hidden={!hideControls}>
                    <FormattedMessage id={`addBusiness.page.sections.${entityType}.superadmin.addandapprove.acknowledge`} />
                </Button>
                <Button
                    type="button"
                    skin="primary"
                    disabled={disableSubmit}
                    onClick={onSubmit}
                    hidden={hideControls}
                    loading={inprogress}
                    loadingMessage={intl.formatMessage({id: `addBusiness.page.sections.${entityType}.superadmin.addandapprove.submit` })}
                >
                    <FormattedMessage id={`addBusiness.page.sections.${entityType}.superadmin.addandapprove.submit`} />
                </Button>
            </ButtonArea>
        </>
    );
};
