import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import {authTheme} from "baseline-ui/auth";
import { get, has } from 'lodash';
import { typographyUtils } from 'baseline-ui/typography';

const WelcomeMainContainer = styled.div`
    background-color: transparent; 
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    display: flex;
    flex-direction: column;
    justify-content:  ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'center'};
    min-height: 55vh;
    padding-top: ${({padding}) => {
        if(has(padding, 'top')) {
            return typographyUtils.processSize(padding.top)
        }

        return rem(16);
    }};
    padding-right: ${({padding}) => {
        if(has(padding, 'right')) {
            return typographyUtils.processSize(padding.right)
        }

        return rem(16);
    }};
    padding-bottom: ${({padding}) => {
        if(has(padding, 'bottom')) {
            return typographyUtils.processSize(padding.bottom)
        }

        return rem(16);
    }};
    padding-left: ${({padding}) => {
        if(has(padding, 'left')) {
            return typographyUtils.processSize(padding.left)
        }

        return rem(16);
    }};
    position: relative;
    z-index: 1;
    overflow: hidden;
    ${({ border }) =>
    border &&
    css`
        border-width: 1px;
        border-style: solid;
        border-color: ${({ theme }) => get(theme, 'auth.border.color', authTheme.auth.border.color)}; 
        border-radius: ${({ theme }) => rem(authTheme.auth.border.borderRadius)};
        background-color: ${({ theme }) =>  theme.site.welcome.bgEnd};
       
    `}
    ${media.md`
        border-radius: unset;
        margin: unset;
        height: fit-content;
        padding-left: 0;
        padding-right: 0; 
        padding-bottom: ${rem(16)};
        margin-bottom: ${rem(175)};
        ${({ border }) =>
            border &&
            css`
                background-color: ${({ theme }) =>  theme.site.welcome.bgEnd}; 
                padding-left: ${rem(40)};
                padding-right: ${rem(40)};
                border-radius: ${() => rem(authTheme.auth.border.borderRadius)};
            `}
    `};  
`;

export default WelcomeMainContainer;
