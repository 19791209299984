import { ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_DESTINATION, ENTITY_TYPE_PEOPLE } from 'common/consts';
import {
	GET_ENTITY_LINKS_TO_ENTITY_RESET,
	GET_ENTITY_LINKS_TO_ENTITY_START,
	GET_ENTITY_LINKS_TO_ENTITY_ERROR,
	GET_ENTITY_LINKS_TO_ENTITY_SUCCESS,
	SEND_ENTITY_LINK_REQUEST_RESET,
	SEND_ENTITY_LINK_REQUEST_START,
	SEND_ENTITY_LINK_REQUEST_ERROR,
	SEND_ENTITY_LINK_REQUEST_SUCCESS,
	SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET,
	SEND_AND_APPROVE_ENTITY_LINK_REQUEST_START,
	SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR,
	SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS,
	UPDATE_ENTITY_LINKS_TO_ENTITY_RESET,
	UPDATE_ENTITY_LINKS_TO_ENTITY_START,
	UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR,
	UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS,
	RESEND_ENTITY_LINK_REQUEST_START,
	RESEND_ENTITY_LINK_REQUEST_RESET,
	RESEND_ENTITY_LINK_REQUEST_ERROR,
	RESEND_ENTITY_LINK_REQUEST_SUCCESS,
	DELETE_ENTITY_LINK_RESET,
	DELETE_ENTITY_LINK_START,
	DELETE_ENTITY_LINK_ERROR,
	DELETE_ENTITY_LINK_SUCCESS,
} from '../actions/EntityLinkActions';

const initialState = {
	requestId: '',
	status: '',
	code: '',
	people: [],
	businesses: [],
	destinations: [],
	inprogress: false,
	error: null,
	success: null,
	sendEntityLinkRequestState: {
		inprogress: false,
		error: null,
		success: null,
	},
	sendAndApproveEntityLinkRequestState: {
		inprogress: false,
		error: null,
		success: null,
	},
};

const EntityLinkReducer = (state = initialState, action = {}) => {
	const { type, payload } = action;
	switch (type) {
		case SEND_ENTITY_LINK_REQUEST_RESET: {
			return {
				...state,
				sendEntityLinkRequestState: {
					...state.sendEntityLinkRequestState,
					inprogress: false,
					error: null,
					success: null,
				},
			};
		}
		case SEND_ENTITY_LINK_REQUEST_START: {
			return {
				...state,
				sendEntityLinkRequestState: {
					...state.sendEntityLinkRequestState,
					inprogress: true,
				},
			};
		}
		case SEND_ENTITY_LINK_REQUEST_ERROR: {
			return {
				...state,
				sendEntityLinkRequestState: {
					...state.sendEntityLinkRequestState,
					inprogress: false,
					error: payload.error,
				},
			};
		}
		case SEND_ENTITY_LINK_REQUEST_SUCCESS: {
			const { entityRequestedType, item } = payload;

			switch (entityRequestedType) {
				case ENTITY_TYPE_PEOPLE:
					return {
						...state,
						sendEntityLinkRequestState: {
							...state.sendEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						people: [...state.people, item],
					};
				case ENTITY_TYPE_BUSSINESS:
					return {
						...state,
						sendEntityLinkRequestState: {
							...state.sendEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						businesses: [...state.businesses, item],
					};
				case ENTITY_TYPE_DESTINATION:
					return {
						...state,
						sendEntityLinkRequestState: {
							...state.sendEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						destinations: [...state.destinations, item],
					};
				default:
					return state;
			}
		}
		case SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET: {
			return {
				...state,
				sendAndApproveEntityLinkRequestState: {
					...state.sendAndApproveEntityLinkRequestState,
					inprogress: false,
					error: null,
					success: null,
				}
			};
		}
		case SEND_AND_APPROVE_ENTITY_LINK_REQUEST_START: {
			return {
				...state,
				sendAndApproveEntityLinkRequestState: {
					...state.sendAndApproveEntityLinkRequestState,
					inprogress: true
				}
			};
		}
		case SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR: {
			return {
				...state,
				sendAndApproveEntityLinkRequestState: {
					...state.sendAndApproveEntityLinkRequestState,
					inprogress: false,
					error: payload.error
				}
			};
		}
		case SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS: {
			const { entityRequestedType, item } = payload;

			switch (entityRequestedType) {
				case ENTITY_TYPE_PEOPLE:
					return {
						...state,
						sendAndApproveEntityLinkRequestState: {
							...state.sendAndApproveEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						people: [...state.people, item],
					};
				case ENTITY_TYPE_BUSSINESS:
					return {
						...state,
						sendAndApproveEntityLinkRequestState: {
							...state.sendAndApproveEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						businesses: [...state.businesses, item],
					};
				case ENTITY_TYPE_DESTINATION:
					return {
						...state,
						sendAndApproveEntityLinkRequestState: {
							...state.sendAndApproveEntityLinkRequestState,
							inprogress: false,
							success: true,
						},
						destinations: [...state.destinations, item],
					};
				default:
					return state;
			}
		}
		case UPDATE_ENTITY_LINKS_TO_ENTITY_RESET: {
			return {
				...state,
				inprogress: false,
				error: null,
				success: null,
			};
		}
		case UPDATE_ENTITY_LINKS_TO_ENTITY_START: {
			return {
				...state,
				inprogress: true,
			};
		}
		case UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR: {
			return {
				...state,
				inprogress: false,
				error: payload.error,
			};
		}
		case UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS: {
			const { entityRequestedType, entityLinkRequestsId, approvalstatus } = payload;

			switch (entityRequestedType) {
				case ENTITY_TYPE_PEOPLE:
					return {
						...state,
						people: state.people.map((entity) => {
							if (entity._id === entityLinkRequestsId) {
								return {
									...entity,
									approvalstatus,
								};
							}
							return entity;
						}),
					};
				case ENTITY_TYPE_BUSSINESS:
					return {
						...state,
						businesses: state.businesses.map((entity) => {
							if (entity._id === entityLinkRequestsId) {
								return {
									...entity,
									approvalstatus,
								};
							}
							return entity;
						}),
					};
				case ENTITY_TYPE_DESTINATION:
					return {
						...state,
						destinations: state.destinations.map((entity) => {
							if (entity._id === entityLinkRequestsId) {
								return {
									...entity,
									approvalstatus,
								};
							}
							return entity;
						}),
					};
				default:
					return state;
			}
		}
		case GET_ENTITY_LINKS_TO_ENTITY_RESET: {
			return {
				...state,
				people: [],
				destinations: [],
				businesses: [],
				inprogress: false,
				error: '',
			};
		}
		case GET_ENTITY_LINKS_TO_ENTITY_START: {
			return {
				...state,
				inprogress: true,
			};
		}
		case GET_ENTITY_LINKS_TO_ENTITY_SUCCESS: {
			const { entityRequestingType, entityRequestedType, results } = payload;
			const copiedState = { ...state };
			const entityType = entityRequestedType || entityRequestingType;

			switch (entityType.toUpperCase()) {
				case ENTITY_TYPE_PEOPLE:
					copiedState.people = results;
				case ENTITY_TYPE_DESTINATION:
					copiedState.destinations = results;
					break;
				case ENTITY_TYPE_BUSSINESS:
					copiedState.businesses = results;
					break;
				default:
					break;
			}

			return {
				...copiedState,
				inprogress: false,
			};
		}
		case GET_ENTITY_LINKS_TO_ENTITY_ERROR: {
			return {
				...state,
				inprogress: false,
				error: payload.error,
			};
		}
		case DELETE_ENTITY_LINK_RESET: {
			return {
				...state,
				inprogress: false,
				error: null,
				success: null,
			};
		}
		case DELETE_ENTITY_LINK_START: {
			return {
				...state,
				inprogress: true,
				error: null,
				success: null,
			};
		}
		case DELETE_ENTITY_LINK_ERROR: {
			return {
				...state,
				inprogress: false,
				error: payload.error,
			};
		}
		case DELETE_ENTITY_LINK_SUCCESS: {
			switch (payload.entityType) {
				case ENTITY_TYPE_PEOPLE:
					return {
						...state,
						inprogress: false,
						success: true,
						people: state.people.filter((entity) => {
							if (entity._id !== payload.id) {
								return entity;
							}
						}),
					};
				case ENTITY_TYPE_BUSSINESS:
					return {
						...state,
						inprogress: false,
						success: true,
						businesses: state.businesses.filter((entity) => {
							if (entity._id !== payload.id) {
								return entity;
							}
						}),
					};
				case ENTITY_TYPE_DESTINATION:
					return {
						...state,
						inprogress: false,
						success: true,
						destinations: state.destinations.filter((entity) => {
							if (entity._id !== payload.id) {
								return entity;
							}
						}),
					};
				default:
					return state;
			}
		}
		case RESEND_ENTITY_LINK_REQUEST_RESET: {
			return {
				...state,
				inprogress: false,
				error: null,
				success: null,
			};
		}
		case RESEND_ENTITY_LINK_REQUEST_START: {
			return {
				...state,
				inprogress: true,
			};
		}
		case RESEND_ENTITY_LINK_REQUEST_SUCCESS: {
			switch (payload.entityType) {
				case ENTITY_TYPE_PEOPLE:
					return {
						...state,
						inprogress: false,
						success: true,
						people: state.people.map((entity) => {
							if (entity._id === payload.entity._id) {
								return payload.entity;
							}

							return entity;
						}),
					};
				case ENTITY_TYPE_BUSSINESS:
					return {
						...state,
						inprogress: false,
						success: true,
						businesses: state.businesses.map((entity) => {
							if (entity._id === payload.entity._id) {
								return payload.entity;
							}

							return entity;
						}),
					};
				case ENTITY_TYPE_DESTINATION:
					return {
						...state,
						inprogress: false,
						success: true,
						destinations: state.destinations.map((entity) => {
							if (entity._id === payload.entity._id) {
								return payload.entity;
							}

							return entity;
						}),
					};
				default:
					return state;
			}
		}
		case RESEND_ENTITY_LINK_REQUEST_ERROR: {
			return {
				...state,
				inprogress: false,
				error: payload.error,
			};
		}
		default: {
			return state;
		}
	}
};
export default EntityLinkReducer;
