import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Small } from 'baseline-ui/typography';
import EntityLinkRequestSentItem from './EntityLinkRequestSentItem';
import EntityLinkActionModal from '../EntityLinkActionModal';
import { ModalContext } from 'baseline-ui/modal';
import { ENTITY_LINK_ACTION_MODAL_MODES } from 'common/consts';

const EntityLinkRequestSentList = (props) => {
    const { items, entityType } = props;
    const { showModal } = useContext(ModalContext);
    const entityTypeLowerCase = entityType.toLowerCase();

    const onLinkRemoveHandler = (item) => {
        showModal(EntityLinkActionModal, {
            mode: ENTITY_LINK_ACTION_MODAL_MODES.REMOVE,
            entityLink: item,
            entityType
        })
    };

    const onLinkResendHandler = (item) => {
        showModal(EntityLinkActionModal, {
            mode: ENTITY_LINK_ACTION_MODAL_MODES.RESEND,
            entityLink: item,
            entityType
        })
    };

    return (
        <>
            {items.length ? (
                items.map((item) => (
                    <EntityLinkRequestSentItem
                        data={item}
                        key={item._id}
                        onRemove={onLinkRemoveHandler}
                        onResend={onLinkResendHandler}
                    />
                ))
            ) : (
                <Small>
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.pending.empty`}
                    />
                </Small>
            )}
        </>
    );
};

export default EntityLinkRequestSentList;
