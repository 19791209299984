import React from 'react';
import MobileView from './MobileView';
import TabletDesktopView from './TabletDesktopView';

const Intro = () => {
    return (
        <>
            <MobileView />
            <TabletDesktopView />
        </>
    );
};

export default Intro;
