import { LinkButton } from 'baseline-ui/button';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';
import { TabletDesktop } from 'baseline-ui/layout';
import { H1 } from 'baseline-ui/typography';
import { CREATE_FAVEPAGE } from 'common/pathnames';
import FlexWrapper from 'components/FlexWrapper';
import Label from 'components/Label';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import siteBaseTheme from 'styles/siteBaseTheme';

const TabletDesktopView = () => {
    return (
        <TabletDesktop>
            <FlexWrapper
                background={siteBaseTheme.base.colors.transparent}
                xsGap={0}
                mdGap={0}
                margin={{
                    top: 66,
                    bottom: 180,
                }}
                padding={{
                    top: 0,
                    bottom: 0,
                }}
            >
                <FormattedMessage
                    id="businessScreen.title"
                    values={{
                        h1: (content) => <H1 textAlign="center">{content}</H1>,
                        text: (content) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={65}
                                fontSize={54}
                                fontWeight={800}
                                margin={{
                                    top: 0,
                                    bottom: 40,
                                }}
                            >
                                {content}
                            </Label>
                        ),
                    }}
                />

                <FormattedMessage
                    id="businessScreen.subtitle"
                    values={{
                        text: (content) => (
                            <Label
                                display="block"
                                textAlign="center"
                                skin="secondary"
                                lineHeight={26}
                                fontSize={22}
                                fontWeight={600}
                                margin={{
                                    bottom: 40,
                                    left: 25,
                                    right: 25,
                                }}
                            >
                                {content}
                            </Label>
                        ),
                    }}
                />

                <ButtonWrapper width={240}>
                    <LinkButton block size="lg" to={CREATE_FAVEPAGE}>
                        <FormattedMessage id="businessScreen.button.createFavePage.label" />
                    </LinkButton>
                </ButtonWrapper>
            </FlexWrapper>
        </TabletDesktop>
    );
}

export default TabletDesktopView