import React from 'react';
import PropTypes from 'prop-types';
import FootnoteContainer from './FootnoteContainer';

const AuthFootnote = ({ footnote }) => {
    if (!footnote) {
        return null;
    }

    return (
        <FootnoteContainer>{footnote}</FootnoteContainer>
    );
};

AuthFootnote.propTypes = {
    footnote: PropTypes.node,
};

AuthFootnote.defaultProps = {
    footnote: undefined,
};

export default AuthFootnote;
