import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormSignIn } from 'baseline-ui/auth';
import { FormattedMessage, useIntl } from 'react-intl';
import {FORGOT_PASSWORD_PATH, SIGNUP_PATH} from 'common/pathnames';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import CommonWrapper from './CommonWrapper';
import { POST_LOGIN_PROCESS, POST_LOGIN_START } from '../redux/actions/AuthenticationActions';
import { useLoading } from '../common/useLoading';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import Background from 'baseline-ui/background';
import { BACKGROUND_MODES } from 'common/consts';
import FlexWrapper from 'components/FlexWrapper';
import ExtraLargeH1 from 'baseline-ui/typography/src/components/ExtraLargeH1';
import siteBaseTheme from 'styles/siteBaseTheme';

const LoginRoute = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useLoading(POST_LOGIN_PROCESS);
    const errorMessage = useProcessErrorMessage({ processName: POST_LOGIN_PROCESS });

    return (
        <CommonWrapper>
            <Background mode={BACKGROUND_MODES.HEART}>
                <WelcomeMainContainer
                justifyContent='flex-start'
            >
                <ExtraLargeH1 colorValue='secondary' fontSize={48} textAlign='center' fontWeight={800} 
                    margin={{
                    top: 50,
                    bottom: 30,
                    }}
                >
                    <FormattedMessage id="login.title" />
                </ExtraLargeH1>
                <FlexWrapper 
                    background={siteBaseTheme.base.colors.transparent}
                    width={35}
                    maxWidth={415}
                    padding={{
                        right: 0,
                        left: 0
                    }}
                >
                <FormSignIn
                    showHeading={false}
                    siteName={intl.formatMessage({ id: 'siteName' })}
                    onSubmitSignIn={(values) => {
                        const { emailAddress, password } = values;
                        dispatch({
                            type: POST_LOGIN_START,
                            payload: {
                                email: emailAddress,
                                password,
                                navigate,
                            },
                        });
                    }}
                    errorMessage={errorMessage}
                    isLoading={isLoading}
                    inProp
                    toSignUp={SIGNUP_PATH}
                    appear={false}
                    toRecoverPassword={FORGOT_PASSWORD_PATH}
                />
                </FlexWrapper>
            </WelcomeMainContainer>
            </Background>
        </CommonWrapper>
    );
};

export default LoginRoute;
