import styled from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const ButtonsWrapper = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: ${rem(16)};
     width: 100%;
    ${media.md`
         flex-direction: row;
         margin: 0 auto;
         width: ${({width}) =>
            width
                ? rem(width/1.5)
                : '100%'
        };
    `};
        ${media.lg`
            flex-direction: row;
            margin: 0 auto;
            width: ${({width}) =>
                width
                ? rem(width)
                : '100%'
            };
    `};
`;

export default ButtonsWrapper;