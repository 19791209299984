import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import BackgroundBase from './BackgroundBase'
import heartFadedRight from '../images/heart-fade-side-right.svg';
import heartFadedLeft from '../images/heart-fade-side-left.svg';

const BackgroundHeartSideSpaceBetweenFade = styled(BackgroundBase)`
    &::before {
        content: '';
        background-repeat: no-repeat;
        background-position: 0 ${rem(50)};
        position: absolute;
        background-image: url(${heartFadedRight});
        top: ${rem(50)};
        left: 0;
        height: 75%;
        width: 100%;
        z-index: 0;
        ${media.sm`
          width: 50%;
        `}
    }

    &::after {
        content: unset;
        background-repeat: no-repeat;
        background-position: 100% ${rem(50)};
        background-image: url(${heartFadedLeft});
        position: absolute;
        top: ${rem(50)};
        right: 0;
        width: 50%;
        height: 75%;
        z-index: 0;
        ${media.sm`
          content: '';
          background-image: url(${heartFadedLeft});
        `}
    }
`;

export default BackgroundHeartSideSpaceBetweenFade