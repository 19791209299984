
import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import BackgroundBase from './BackgroundBase'
import triangleTopLeftSmall from '../images/triangle-top-left-small.svg';
import triangleBottomLeft from '../images/triangle-bottom-left.svg';
import triangleBottomRightSmall from '../images/triangle-bottom-right-small.svg';
import triangleBottomRight from '../images/triangle-bottom-right.svg';

const BackgroundTriangleSpaceBetween = styled(BackgroundBase)`
    &::before {
        background-image: url(${triangleTopLeftSmall});
        background-position: left top;
        background-repeat: no-repeat;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        ${media.md`
          background-image: url(${triangleBottomLeft});
          background-position: left bottom;
        `}
    }

    &::after {
        background-image: url(${triangleBottomRightSmall});
        background-position: right bottom;
        background-repeat: no-repeat;
        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;
      ${media.md`
          background-image: url(${triangleBottomRight});
      `}  
    }
`;

export default BackgroundTriangleSpaceBetween