import styled from "styled-components";
import {rem} from "polished";
import {media} from "../../../layout";

const ButtonWrapper = styled.div`
     display: flex;
     justify-content: ${({justifyContent}) =>
        justifyContent
            ? justifyContent
            : 'center'
     };
     width: ${({width}) =>
            width
                ? rem(width)/1.8
                : '100%'
     };  
    margin: ${({ marginY, marginX }) => {
        if (marginY && marginX) {
            return `${rem(marginY / 2)} ${rem(marginX / 2)}`;
        }

        if (marginY) {
            return `${rem(marginY / 2)} auto`;
        }

        if (marginX) {
            return `auto ${rem(marginX / 2)}`;
        }

        return `0 auto`;
    }};
     z-index: 1;
     ${media.xs`
        margin: ${({ marginY, marginX }) => {
            if (marginY && marginX) {
                return `${rem(marginY)} ${rem(marginX)}`;
            }

            if (marginY) {
                return `${rem(marginY)} auto`;
            }

            if (marginX) {
                return `auto ${rem(marginX)}`;
            }

            return `0 auto`;
        }};
        width: ${({ width }) => (width ? rem(width) : '100%')};
   `};
    ${media.sm`
        margin: ${({ marginY, marginX }) => {
            if (marginY && marginX) {
                return `${rem(marginY)} ${rem(marginX)}`;
            }

            if (marginY) {
                return `${rem(marginY)} auto`;
            }

            if (marginX) {
                return `auto ${rem(marginX)}`;
            }

            return `0 auto`;
        }};
        width: ${({ width }) => (width ? rem(width) : '100%')};
    `};
`;

export default ButtonWrapper;
