import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import BackgroundBase from './BackgroundBase'
import gradientBottom from '../images/gradient-bottom.svg'
import triangleMiddleLeft from '../images/triangle-middle-left.svg'
import triangleMiddleRight from '../images/triangle-middle-right.svg'

const BackgroundTriangleSidesMiddleGradientBottom = styled(BackgroundBase)`
  background: url(${gradientBottom}) left bottom repeat-x;

  ${media.md`
      background: 
        url(${triangleMiddleLeft}) left 36% no-repeat, 
        url(${triangleMiddleRight}) right 36% no-repeat,
        url(${gradientBottom}) left bottom repeat-x;
    `}
  }
`;

export default BackgroundTriangleSidesMiddleGradientBottom