import styled from 'styled-components';
import {rem} from 'polished';

const ButtonBlock = styled.div`
     display: flex;
     justify-content: ${({justifyContent}) =>
    justifyContent
        ? justifyContent
        : 'center'
};
     width: ${({width}) =>
    width
        ? rem(width)
        : '100%'
};  
    margin: ${({ marginY, marginX }) => {
    if (marginY && marginX) {
        return `${rem(marginY / 2)} ${rem(marginX / 2)}`;
    }

    if (marginY) {
        return `${rem(marginY / 2)} auto`;
    }

    if (marginX) {
        return `auto ${rem(marginX / 2)}`;
    }

    return `0 auto`;
}};
     z-index: 1;

`;

export default ButtonBlock;
