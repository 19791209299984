import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import { rem } from 'polished';
import BackgroundBase from './BackgroundBase'
import heartLeft from '../images/heart-side-left.svg';
import heartRight from '../images/heart-side-right.svg';

const BackgroundHeartSideSpaceBetweenMobileRight = styled(BackgroundBase)`
  background: url(${heartLeft}) right 58% no-repeat;

  ${media.sm`
    background: url(${heartRight}) left ${rem(50)} no-repeat,
              url(${heartLeft}) right ${rem(50)} no-repeat;

  `}
`;

export default BackgroundHeartSideSpaceBetweenMobileRight