import styled from 'styled-components';
import siteBaseTheme from 'styles/siteBaseTheme';
import {rem} from 'polished';

const ItemLabel = styled.span`
  font-weight: 700;
  position: relative;
  font-size: ${rem(34)};
  &:hover {
    color: orange;
  }
   &::before {
   content: '';
    width: ${rem(8)};
    height: ${rem(8)};
    background-color: ${siteBaseTheme.base.colors.primary};
    position: absolute;
    left: ${rem(-20)};
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: block;
  text-align: left;
`;

export default ItemLabel;