import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { readableColor } from 'polished';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { ThemeModeContext } from 'baseline-ui/theme-provider';
import { Container, Row, Col, Desktop, MobileTablet } from 'baseline-ui/layout';
import { Image, SrLabel } from 'baseline-ui/helpers';
import { modalPropTypes } from 'baseline-ui/modal';
import HeaderContainer from './HeaderContainer';
import LogoContainer from './LogoContainer';
import Inner from './Inner';
import Title from './Title';
import Navigation from '../Navigation';
import headerTheme from '../../styles/headerTheme';
import headerPropTypes from '../../prop-types/headerPropTypes';
import MobileMenu from '../MobileMenu';
import {ACTIVE_USER, HAMBURGER, USER, X_ICON} from 'images';
import Wrapper from '../MobileMenu/Wrapper';

const Header = ({
    headerColors,
    logoPath,
    logoNavigateTo,
    logoPathAlt,
    logoWidth,
    logoLabel,
    title,
    isVertical,
    isFullWidth,
    navigationList,
    menuModalNavigationList,
    menuModalSecondaryList,
    onClickLinkCallback,
    currentPath,
    isBlurred,
    isBackdropBlurred,
    scrollbarStylesColor,
}) => {
    const theme = useContext(ThemeContext);
    const { themeMode } = useContext(ThemeModeContext);

    const backgroundColor = get(
        theme,
        'header.backgroundColor',
        headerTheme.header.backgroundColor,
    );
    const defaultLogoWidth = get(theme, 'header.logo.width', headerTheme.header.logo.width);

    let renderTitleColor;
    const accountItem = Array.isArray(navigationList[navigationList.length - 1])
        ? navigationList[navigationList.length - 1]
        : [navigationList[navigationList.length - 1]];
    const itemList = navigationList.slice(0, -1);
    if (headerColors) {
        renderTitleColor = readableColor(headerColors[0], 'dark', 'light', false);
    } else if (themeMode) {
        renderTitleColor =
            themeMode === 'darkMode'
                ? readableColor(backgroundColor, 'light', 'dark', false)
                : readableColor(backgroundColor, 'dark', 'light', false);
    } else {
        renderTitleColor = readableColor(backgroundColor, 'dark', 'light', false);
    }

    const renderLogo = (
        <Image
            src={readableColor(backgroundColor, logoPath, logoPathAlt, false)}
            logoWidth={logoWidth || defaultLogoWidth}
        />
    );

    return (
        <HeaderContainer
            headerColors={headerColors}
            isVertical={isVertical}
            isBlurred={isBlurred}
            isBackdropBlurred={isBackdropBlurred}
        >
            <Inner isVertical={isVertical} isFullWidth={isFullWidth}>
                <Container isFullHeight={false} flex="1 1 auto">
                    <Row justifyContent="space-between" alignItems="center">
                        <Col xxs={isVertical ? 30 : null}>
                            <LogoContainer isVertical={isVertical}>
                                {(logoPath || logoPathAlt) && (
                                    <Link to={logoNavigateTo} onClick={onClickLinkCallback}>
                                        {renderLogo}
                                        {logoLabel && <SrLabel>{logoLabel}</SrLabel>}
                                    </Link>
                                )}
                                {title && (
                                    <Title
                                        color={renderTitleColor}
                                        isVertical={isVertical}
                                        hasLogo={!!(logoPath || logoPathAlt)}
                                    >
                                        {title}
                                    </Title>
                                )}
                            </LogoContainer>
                        </Col>

                        {itemList && (
                            <Col xxs={isVertical ? 30 : null} padding={0}>
                                <Desktop>
                                    <Navigation
                                        isVertical={isVertical}
                                        list={itemList}
                                        onClickLinkCallback={onClickLinkCallback}
                                        currentPath={currentPath}
                                        scrollbarStylesColor={scrollbarStylesColor}
                                    />
                                </Desktop>
                                <MobileTablet>
                                    <Wrapper>
                                        <MobileMenu
                                            items={menuModalSecondaryList || navigationList}
                                            icon={USER}
                                            activeIcon={ACTIVE_USER}
                                            key='accountOptions'
                                        />
                                        <MobileMenu
                                            items={menuModalNavigationList || navigationList}
                                            icon={HAMBURGER}
                                            activeIcon={X_ICON}
                                            key='menuOptions'
                                        />
                                    </Wrapper>
                                </MobileTablet>
                            </Col>
                        )}
                        <Desktop>
                            <Col xxs={isVertical ? 30 : null} padding={0}>
                                <Navigation
                                    isVertical={isVertical}
                                    list={accountItem}
                                    onClickLinkCallback={onClickLinkCallback}
                                    currentPath={currentPath}
                                    scrollbarStylesColor={scrollbarStylesColor}
                                />
                            </Col>
                        </Desktop>
                    </Row>
                </Container>
            </Inner>
        </HeaderContainer>
    );
};

Header.propTypes = {
    headerColors: PropTypes.arrayOf(PropTypes.string),
    logoPath: PropTypes.string,
    logoNavigateTo: PropTypes.string,
    logoPathAlt: PropTypes.string,
    logoLabel: PropTypes.string,
    logoWidth: PropTypes.number,
    title: PropTypes.string,
    isVertical: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    navigationList: headerPropTypes.list,
    menuModalNavigationList: headerPropTypes.list,
    menuModalSecondaryList: modalPropTypes.modalFooterActions,
    onClickLinkCallback: PropTypes.func,
    currentPath: PropTypes.string,
    isBlurred: PropTypes.bool,
    isBackdropBlurred: PropTypes.bool,
    scrollbarStylesColor: PropTypes.string,
};

Header.defaultProps = {
    headerColors: undefined,
    logoPath: undefined,
    logoNavigateTo: undefined,
    logoPathAlt: undefined,
    logoLabel: undefined,
    logoWidth: undefined,
    title: undefined,
    isVertical: false,
    isFullWidth: false,
    navigationList: undefined,
    menuModalNavigationList: undefined,
    menuModalSecondaryList: undefined,
    onClickLinkCallback: () => {},
    currentPath: undefined,
    isBlurred: false,
    isBackdropBlurred: false,
    scrollbarStylesColor: undefined,
};

export default Header;
