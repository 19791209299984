import { call, put, select, takeEvery, all } from 'redux-saga/effects';
import { GetBusinessMapping, getEntityTopCategory, GetTag } from 'api';
import {
    GET_BUSINESS_CATEGORIES_PROCESS,
    GET_BUSINESS_CATEGORIES_START,
    GET_BUSINESS_CATEGORIES_SUCCESS,
    GET_ITEM_CATEGORIES_PROCESS,
    GET_ITEM_CATEGORIES_START,
    GET_ITEM_CATEGORIES_SUCCESS,
    GET_TOP_LEVEL_GROUPS_PROCESS,
    GET_TOP_LEVEL_GROUPS_START,
    GET_TOP_LEVEL_GROUPS_SUCCESS,
    GET_BUSINESS_SUBCATEGORIES_PROCESS,
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORIES_SUCCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    CLEAR_BUSINESS_CATEGORIES,
    GET_SEARCH_CATEGORIES_PROCESS,
    GET_SEARCH_CATEGORIES_START,
    GET_INTERESTS_CATEGORY_START,
    GET_INTERESTS_CATEGORY_SUCCESS,
    GET_INTERESTS_CATEGORY_PROCESS,
} from '../actions/CategoriesActions';
import genericSagaHandler from './CommonSaga';
import { ENTITY_TYPE_BUSSINESS, ENTITY_TYPE_DESTINATION } from 'common/consts';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

function* getCategoriesSaga() {
    yield genericSagaHandler(GET_ITEM_CATEGORIES_PROCESS, function* () {
        const type = yield select(
            ({ EditProductReducer }) => EditProductReducer.productToEdit.type,
        );

        const topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        const productGroup = topLevelGroups.find(
            (group) => group.name.toLowerCase() === type.toLowerCase(),
        );
        if (productGroup) {
            const businessCategories = yield GetBusinessMapping(productGroup._id);
            const productCategoriesWithCrumbs = businessCategories.map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), productGroup],
            }));

            yield put({
                type: GET_ITEM_CATEGORIES_SUCCESS,
                payload: productCategoriesWithCrumbs,
            });
        }
    });
}
function* fetchFiltersForHeader(headerId) {
    const filters = yield GetBusinessMapping(headerId);
    return { headerId, filters };
}

function* getBusinessSubcategoryFiltersSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS, function* () {
        const { subcategoryId, categoryId } = action.payload;
        const state = yield select();

        if (subcategoryId && categoryId) {
            yield* handleSubcategoryFilters(subcategoryId, categoryId, state);
        } else if (categoryId && subcategoryId === '') {
            const cachedCategoryFilters =
                state.CategoriesReducer?.businessSubcategoryFilters?.[categoryId];
            if (!cachedCategoryFilters) {
                const businessCategoryHeaders = yield GetBusinessMapping(categoryId);
                const currentCategoryInBusiness = state.CategoriesReducer?.businessCategories?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategoryInItems = state.CategoriesReducer?.itemCategories?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategoryInInterests = state.CategoriesReducer?.interestsCategory?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategory =
                    currentCategoryInBusiness ||
                    currentCategoryInItems ||
                    currentCategoryInInterests;
                const { crumbs: currentCategoryCrumbs, ...currentCategoryWithoutCrumbs } =
                    currentCategory;
                const filters = yield all(
                    businessCategoryHeaders.map((header) =>
                        call(fetchFiltersForHeader, header._id),
                    ),
                );

                const data = businessCategoryHeaders.map((header) => ({
                    ...header,
                    filters: filters
                        .find((filter) => filter.headerId === header._id)
                        .filters.map((filter) => ({
                            ...filter,
                            id: filter._id,
                            crumbs: [
                                ...(currentCategoryCrumbs || []),
                                currentCategoryWithoutCrumbs,
                                header,
                            ],
                        })),
                }));

                yield put({
                    type: GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
                    payload: { subcategoryId: categoryId, data },
                });
            }
        }
    });
}
function* handleSubcategoryFilters(subcategoryId, categoryId, state) {
    const cachedSubcategoriesFilters =
        state.CategoriesReducer?.businessSubcategoryFilters?.[subcategoryId];

    if (!cachedSubcategoriesFilters) {
        const businessSubcategoryHeaders = yield GetBusinessMapping(subcategoryId);
        const filters = yield all(
            businessSubcategoryHeaders.map((header) => call(fetchFiltersForHeader, header._id)),
        );

        const currentSubcategory = state.CategoriesReducer?.businessSubcategories?.[
            categoryId
        ]?.find((subcategory) => subcategory._id === subcategoryId);

        const { crumbs: currentSubcategoryCrumbs, ...currentSubcategoryWithoutCrumbs } =
            currentSubcategory;

        const data = businessSubcategoryHeaders.map((header) => ({
            ...header,
            filters: filters
                .find((filter) => filter.headerId === header._id)
                .filters.map((filter) => ({
                    ...filter,
                    id: filter._id,
                    crumbs: [
                        ...(currentSubcategoryCrumbs || []),
                        currentSubcategoryWithoutCrumbs,
                        header,
                    ],
                })),
        }));

        yield put({
            type: GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
            payload: { subcategoryId, data },
        });
    }
}
function* getBusinessSubcategoriesSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_SUBCATEGORIES_PROCESS, function* () {
        const { categoryId } = action.payload;
        const state = yield select();
        const currentCategoryInBusiness = state.CategoriesReducer?.businessCategories?.find(
            (category) => category._id === categoryId,
        );
        const currentCategoryInItems = state.CategoriesReducer?.itemCategories?.find(
            (category) => category._id === categoryId,
        );
        const currentCategoryInInterests = state.CategoriesReducer?.interestsCategory?.find(
            (category) => category._id === categoryId,
        );
        const cachedSubcategories = state.CategoriesReducer?.businessSubcategories?.[categoryId];

        if (!cachedSubcategories) {
            const subcategories = yield GetBusinessMapping(categoryId);
            const isHeaderPresent = subcategories.some((item) => item.type === 'header');
            if (isHeaderPresent) {
                yield call(getBusinessSubcategoryFiltersSaga, {
                    payload: { subcategoryId: '', categoryId: categoryId },
                });
            } else {
                const currentCategory =
                    currentCategoryInBusiness ||
                    currentCategoryInItems ||
                    currentCategoryInInterests;

                const { crumbs: currentCategoryCrumbs, ...currentCategoryWithoutCrumbs } =
                    currentCategory;

                const data = subcategories.map((subcategory) => ({
                    ...subcategory,
                    id: subcategory._id,
                    crumbs: [
                        ...(subcategory.crumbs || []),
                        ...(currentCategoryCrumbs || []),
                        currentCategoryWithoutCrumbs,
                    ],
                }));

                yield put({
                    type: GET_BUSINESS_SUBCATEGORIES_SUCCESS,
                    payload: { categoryId, data },
                });
            }
        }
    });
}
function* getBusinessCategoriesSaga() {
    yield genericSagaHandler(GET_BUSINESS_CATEGORIES_PROCESS, function* () {
        const _id = yield select(({ BusinessFormReducer }) => BusinessFormReducer._id);
        const entityTypeId = _id.split('/')[0];
        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const [topLevelTagsAllCategories] = yield getEntityTopCategory();
        const topLevelTagId = topLevelTagsAllCategories[entityTypeId];
        const topLevelTag = GetTag(topLevelTagId);
        let businessCategories = yield GetBusinessMapping(topLevelTagId);
        const businessCategoriesWithCrumbs = businessCategories.map((category) => ({
            ...category,
            id: category._id,
            crumbs: [...(category.crumbs || []), topLevelTag],
        }));
        yield put({
            type: GET_BUSINESS_CATEGORIES_SUCCESS,
            payload: businessCategoriesWithCrumbs,
        });
    });
}
function* getCategoriesSearchSaga(actions) {
    yield genericSagaHandler(GET_SEARCH_CATEGORIES_PROCESS, function* () {
        const type =
            actions.payload.type === ENTITY_TYPE_DESTINATION
                ? ENTITY_TYPE_BUSSINESS
                : actions.payload.type;

        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const categoryGroup = topLevelGroups.find(
            (group) => group.name.toLowerCase() === type.toLowerCase(),
        );

        if (categoryGroup) {
            let businessCategories = yield GetBusinessMapping(categoryGroup._id);

            if (actions.payload.type === ENTITY_TYPE_DESTINATION) {
                businessCategories = businessCategories.filter(
                    (item) => item.name.toUpperCase() === ENTITY_TYPE_DESTINATION,
                );
            }

            const businessCategoriesWithCrumbs = businessCategories.map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), categoryGroup],
            }));

            yield put({
                type: GET_BUSINESS_CATEGORIES_SUCCESS,
                payload: businessCategoriesWithCrumbs,
            });
        }
    });
}
function* getTopLevelGroupsSaga() {
    yield genericSagaHandler(GET_TOP_LEVEL_GROUPS_PROCESS, function* () {
        const businessCategories = yield GetBusinessMapping('ROOT');

        yield put({
            type: GET_TOP_LEVEL_GROUPS_SUCCESS,
            payload: businessCategories,
        });
    });
}

function* getInterestCategorySaga(action) {
    yield genericSagaHandler(GET_INTERESTS_CATEGORY_PROCESS, function* () {
        const { interest } = action.payload;
        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const topLevelGroupForType = topLevelGroups.find(
            (group) => group.name.toLowerCase() === 'interests',
        );

        if (!topLevelGroupForType || !topLevelGroupForType._id) {
            yield put({
                type: CLEAR_BUSINESS_CATEGORIES,
            });
        }
        const interestsCategory = yield GetBusinessMapping(topLevelGroupForType._id);

        const interestCategoryWithCrumbs = interestsCategory
            .filter((category) => category.name.toLowerCase() === interest.toLowerCase())
            .map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), topLevelGroupForType],
            }));

        yield put({
            type: GET_INTERESTS_CATEGORY_SUCCESS,
            payload: interestCategoryWithCrumbs,
        });
    });
}

export default function* CategoriesSagas() {
    yield takeEvery(GET_ITEM_CATEGORIES_START, getCategoriesSaga);
    yield takeEvery(GET_BUSINESS_SUBCATEGORY_FILTERS_START, getBusinessSubcategoryFiltersSaga);
    yield takeEvery(GET_BUSINESS_SUBCATEGORIES_START, getBusinessSubcategoriesSaga);
    yield takeEvery(GET_SEARCH_CATEGORIES_START, getCategoriesSearchSaga);
    yield takeEvery(GET_BUSINESS_CATEGORIES_START, getBusinessCategoriesSaga);
    yield takeEvery(GET_TOP_LEVEL_GROUPS_START, getTopLevelGroupsSaga);
    yield takeEvery(GET_INTERESTS_CATEGORY_START, getInterestCategorySaga);
}
