export const GET_ENTITY_LINKS_TO_ENTITY = 'GET_ENTITY_LINKS_TO_ENTITY'
export const GET_ENTITY_LINKS_TO_ENTITY_RESET = 'GET_ENTITY_LINKS_TO_ENTITY_RESET'
export const GET_ENTITY_LINKS_TO_ENTITY_START = 'GET_ENTITY_LINKS_TO_ENTITY_START'
export const GET_ENTITY_LINKS_TO_ENTITY_CALL = 'GET_ENTITY_LINKS_TO_ENTITY_CALL'
export const GET_ENTITY_LINKS_TO_ENTITY_SUCCESS = 'GET_ENTITY_LINKS_TO_ENTITY_SUCCESS'
export const GET_ENTITY_LINKS_TO_ENTITY_ERROR = 'GET_ENTITY_LINKS_TO_ENTITY_ERROR'

export const SEND_ENTITY_LINK_REQUEST = 'SEND_ENTITY_LINK_REQUEST'
export const SEND_ENTITY_LINK_REQUEST_CALL = 'SEND_ENTITY_LINK_REQUEST_CALL'
export const SEND_ENTITY_LINK_REQUEST_RESET = 'SEND_ENTITY_LINK_REQUEST_RESET'
export const SEND_ENTITY_LINK_REQUEST_START = 'SEND_ENTITY_LINK_REQUEST_START'
export const SEND_ENTITY_LINK_REQUEST_ERROR = 'SEND_ENTITY_LINK_REQUEST_ERROR'
export const SEND_ENTITY_LINK_REQUEST_SUCCESS = 'SEND_ENTITY_LINK_REQUEST_SUCCESS'

export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST'
export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST_CALL'
export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST_RESET'
export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST_START = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST_START'
export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST_ERROR'
export const SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS = 'SEND_AND_APPROVE_ENTITY_LINK_REQUEST_SUCCESS'

export const UPDATE_ENTITY_LINKS_TO_ENTITY = 'UPDATE_ENTITY_LINKS_TO_ENTITY'
export const UPDATE_ENTITY_LINKS_TO_ENTITY_RESET = 'UPDATE_ENTITY_LINKS_TO_ENTITY_RESET'
export const UPDATE_ENTITY_LINKS_TO_ENTITY_START = 'UPDATE_ENTITY_LINKS_TO_ENTITY_START'
export const UPDATE_ENTITY_LINKS_TO_ENTITY_CALL = 'UPDATE_ENTITY_LINKS_TO_ENTITY_CALL'
export const UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS = 'UPDATE_ENTITY_LINKS_TO_ENTITY_SUCCESS'
export const UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR = 'UPDATE_ENTITY_LINKS_TO_ENTITY_ERROR'

export const DELETE_ENTITY_LINK = 'DELETE_ENTITY_LINK'
export const DELETE_ENTITY_LINK_CALL = 'DELETE_ENTITY_LINK_CALL'
export const DELETE_ENTITY_LINK_RESET = 'DELETE_ENTITY_LINK_RESET'
export const DELETE_ENTITY_LINK_START = 'DELETE_ENTITY_LINK_START'
export const DELETE_ENTITY_LINK_SUCCESS = 'DELETE_ENTITY_LINK_SUCCESS'
export const DELETE_ENTITY_LINK_ERROR = 'DELETE_ENTITY_LINK_ERROR'

export const RESEND_ENTITY_LINK_REQUEST = 'RESEND_ENTITY_LINK_REQUEST'
export const RESEND_ENTITY_LINK_REQUEST_CALL = 'RESEND_ENTITY_LINK_REQUEST_CALL'
export const RESEND_ENTITY_LINK_REQUEST_RESET = 'RESEND_ENTITY_LINK_REQUEST_RESET'
export const RESEND_ENTITY_LINK_REQUEST_START = 'RESEND_ENTITY_LINK_REQUEST_START'
export const RESEND_ENTITY_LINK_REQUEST_SUCCESS = 'RESEND_ENTITY_LINK_REQUEST_SUCCESS'
export const RESEND_ENTITY_LINK_REQUEST_ERROR = 'RESEND_ENTITY_LINK_REQUEST_ERROR'