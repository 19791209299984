import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {rem} from 'polished';

const BlockWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-bottom: ${rem(16)};
   margin-top: ${rem(16)};
   align-items: baseline;
    ${media.md`
        margin-top: ${rem(-32)};
        flex: 1 1 60%;
        align-items: end;
    `};
`;

export default BlockWrapper;