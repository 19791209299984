import { get } from 'lodash';
import { rem } from 'polished';
import typographyTheme from '../styles/typographyTheme';

const typographyUtils = {
    headingMarginByPaddingRatio: ({ theme, heading }) => {
        return rem(
            get(
                theme,
                `typography.headings.margin.${heading}`,
                typographyTheme.typography.headings.fontSize[heading],
            ) /
                get(
                    theme,
                    `typography.headings.margin.${heading}`,
                    typographyTheme.typography.headings.paddingRatio,
                ),
        );
    },
    processSize: (size) => {
        if (typeof size === 'undefined') {
            return '';
        }
    
        if (String(size).endsWith('%') || size === 'auto') {
            return size;
        }
    
        return rem(Number(size));
    }
};

export default typographyUtils;
