import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { typographyUtils } from 'baseline-ui/typography';
import { has } from 'lodash';

const YoutubeVideo = ({
    videoId = null,
    autoplay = false,
    showControls = true,
    disableKeyboard = false,
    fullScreenButton = true,
    loop = false,
    ...props
}) => {
    const url = new URL(`https://youtube.com/embed/${videoId}`);

    url.searchParams.append('autoplay', autoplay);
    url.searchParams.append('controls', showControls ? 1 : 0);
    url.searchParams.append('disablekb', disableKeyboard ? 1 : 0);
    url.searchParams.append('fs', fullScreenButton ? 1 : 0);
    url.searchParams.append('loop', loop ? 1 : 0);

    return (
        <VideoContainer {...props}>
            <iframe src={url} />
        </VideoContainer>
    );
};

YoutubeVideo.propTypes = {
    videoId: propTypes.string.isRequired,
    autoplay: propTypes.bool,
    showControls: propTypes.bool,
    disableKeyboard: propTypes.bool,
    fullScreenButton: propTypes.bool,
    loop: propTypes.bool,
    margin: propTypes.shape({
        bottom: propTypes.number,
        left: propTypes.number,
        right: propTypes.number,
        top: propTypes.number,
    }),
    padding: propTypes.shape({
        bottom: propTypes.number,
        left: propTypes.number,
        right: propTypes.number,
        top: propTypes.number,
    })
};

const VideoContainer = styled.div`
    height: 0;
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    margin-top: ${({margin}) => {
        if(has(margin, 'top')) {
            return typographyUtils.processSize(margin.top)
        }

        return 0
    }};
    margin-right: ${({margin}) => {
        if(has(margin, 'right')) {
            return typographyUtils.processSize(margin.right)
        }

        return 0
    }};
    margin-bottom: ${({margin}) => {
        if(has(margin, 'bottom')) {
            return typographyUtils.processSize(margin.bottom)
        }

        return 0
    }};
    margin-left: ${({margin}) => {
        if(has(margin, 'left')) {
            return typographyUtils.processSize(margin.left)
        }

        return 0
    }};


    & iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export default YoutubeVideo;
