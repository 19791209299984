import { rem } from 'polished';
import styled from 'styled-components';
import {media} from 'baseline-ui/layout';

const CardBlock = styled.div`
    margin: ${rem(8)};
    position: relative;
    width: 100%; 
    ${media.md`
        width: ${rem(160)};
     `
    };
`;

export default CardBlock;