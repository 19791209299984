import * as yup from "yup";

const formDownloadFavePageApp = [
    yup.object().shape({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        emailAddress: yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: yup.string().required('Phone number is required'),
        zipCode: yup.string().required('Zip code is required'),
    }),
    yup.object().shape({
        device: yup.string().required('Device selection is required'),
    }),
    yup.object().shape({
        accountEmailAddress: yup.string().email('Invalid email').required('Account email is required'),
    }),
];

export default formDownloadFavePageApp;