import styled, { css } from 'styled-components';
import { get } from 'lodash';
import formTheme from '../../styles/formTheme';

const FormInputAutocompleteContainer = styled.div`
    position: 'absolute',
    top: 1px,
    width: '100%',
    background-color: ${({ skin, theme }) => {
        return get(
            theme,
            `form.input.${skin}.backgroundColor`,
            formTheme.form.input[skin].backgroundColor,
        );
    }};
    border-color:${({ skin, theme }) =>
        get(theme, `form.input.${skin}.borderColor`, formTheme.form.input[skin].borderColor)}; 
    border-width: 1px;
    border-style: solid;
    zIndex: 100,
`;

export default FormInputAutocompleteContainer;
