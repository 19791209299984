import styled from 'styled-components';
import {media} from 'baseline-ui/layout';
import {baseTheme} from 'baseline-ui/base-theme';

const CopyrightContainer = styled.div`
    display: flex;
    justify-content: ${({justifyContent}) =>
    justifyContent
        ? `${justifyContent}`
        : 'flex-end'
    };
    align-items: center;
    color: ${baseTheme.base.colors.light};
    flex-direction: column;
    ${media.xs`
        flex-direction: row;
    `};
`;

export default CopyrightContainer;
