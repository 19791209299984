import TitleWrapper from './style-components/TitleWrapper';
import {H3, H5} from 'baseline-ui/typography';
import FlexWrapper from '../../../../components/FlexWrapper';
import IconWrapper from './style-components/IconWrapper';
import {GaIcon} from 'baseline-ui/icon';
import {FieldContainer, FormInput} from 'baseline-ui/form';
import {Field, Formik} from 'formik';
import {Button} from 'baseline-ui/button';
import CustomForm from './style-components/CustomForm';
import React, {useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import formDownloadFavePageApp from 'schema/formDownloadFavePageApp';
import {
    POST_DOWNLOAD_APP_FORM_DATA_START,
    SET_DOWNLOAD_APP_FORM_DATA
} from 'redux/actions/DownloadFavePageAppFormActions';
import {useDispatch, useSelector} from 'react-redux';
import Label from 'components/Label';
import ButtonsWrapper from './style-components/ButtonsWrapper';
import {DEVICE_TYPE_APPLE, DEVICE_TYPE_GOOGLE} from 'common/consts';
import PropTypes from 'prop-types';

const StepThreeForm = ({handlePrevious, handleNext, formData, onValidationError, formikRef}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const formSubmit = useSelector((state) => state.ProcessReducer?.POST_DOWNLOAD_APP_FORM_DATA);
    useEffect(() => {
        if(formData.device && formData.device === DEVICE_TYPE_APPLE && formSubmit === 'SUCCESS'){
            handleNext();
        }
    }, [formSubmit]);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                accountEmailAddress: '',
            }}
            enableReinitialize={true}
            validationSchema={formDownloadFavePageApp[2]}
            onSubmit={() => {
                dispatch({
                    type: POST_DOWNLOAD_APP_FORM_DATA_START,
                    payload: formData,
                });
                if(formData.device && formData.device === DEVICE_TYPE_GOOGLE){
                    dispatch({
                        type: SET_DOWNLOAD_APP_FORM_DATA,
                        payload: {isLastSlide: true},
                    });
                }
            }}
        >
            {({ values, errors, touched }) => {
                const hasErrors = Object.keys(errors).some((key) => touched[key])
                if (hasErrors) {
                    onValidationError();
                }
                useEffect(() => {
                    dispatch({
                        type: SET_DOWNLOAD_APP_FORM_DATA,
                        payload: values,
                    });
                }, [values, dispatch]);

                return (
                    <CustomForm noValidate>
                    <TitleWrapper width={70}>
                        <H5
                            color='primary'
                            fontWeight={500}
                        >
                            <FormattedMessage
                                id='formTestApp.form.step.number.label'
                                values={{
                                    number: 3
                                }}
                            />
                        </H5>
                        <H3
                            fontWeight={600}
                            textAlign='center'
                        >
                            <FormattedMessage
                                id='formTestApp.form.yourDevice.title'
                                values={{
                                    device: formData.device === DEVICE_TYPE_APPLE ? 'AppleID' : 'Google Account',
                                    orange: (text) => <Label skin='primary'>{text}</Label>,
                                }}
                            />
                        </H3>
                    </TitleWrapper>
                    <FlexWrapper border>
                        <IconWrapper>
                            <H5
                                fontWeight={600}
                                textAlign='center'
                            >
                                <FormattedMessage
                                    id='formTestApp.form.yourDevice.subtitle'
                                    values={{
                                        device: formData.device === DEVICE_TYPE_APPLE ? 'AppleID' : 'Google Account',
                                    }}
                                />
                            </H5>
                        </IconWrapper>
                        <FieldContainer>
                            <Field
                                component={FormInput}
                                label={intl.formatMessage({
                                    id: 'auth.emailAddress.label',
                                })}
                                name="accountEmailAddress"
                                type="email"
                                required
                                autoComplete="off"
                            />
                        </FieldContainer>
                    </FlexWrapper>
                     <ButtonsWrapper width={500}>
                        <Button
                            block
                            onClick={handlePrevious}
                            btnType='outline'
                        >
                            <FormattedMessage id='formTestApp.form.back.button' />
                        </Button>
                        <Button
                            block
                            type="submit"
                        >
                            <FormattedMessage id='formTestApp.form.submit.button' />
                        </Button>
                    </ButtonsWrapper>
                </CustomForm>
                );
            }}
        </Formik>
    );
}
StepThreeForm.propTypes = {
    handlePrevious: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    onValidationError: PropTypes.func.isRequired,
    formData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        emailAddress: PropTypes.string.isRequired,
        phoneNumber: PropTypes.number.isRequired,
        zipCode: PropTypes.number.isRequired,
        device: PropTypes.string.isRequired,
        accountEmailAddress: PropTypes.string.isRequired,
    }).isRequired,
};
export default StepThreeForm;