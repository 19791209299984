import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'baseline-ui/layout';
import addBusinessSectionsUtils from 'utils/addBusinessSectionsUtils';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useProcessLoader from 'utils/useProcessLoader';
import { GET_BUSINESS_FORM_PROCESS } from 'redux/actions/BusinessFormActions';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import StatusTag from 'components/StatusTag';
import FavePageMainMyBusinessContainer from './FavePageMainMyBusinessContainer';
import FavePageMainSectionNavigation from './FavePageMainSectionNavigation';
import FavePageMainMyBusinessStatusTagContainer from './FavePageMainMyBusinessStatusTagContainer';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

const FavePageMainMyBusiness = ({
    currentSectionId,
    handleBackNavigation,
    disableBackNavigation,
    handleNextNavigation,
    disableNextNavigation,
}) => {
    const intl = useIntl();
    const { type } = useSelector(businessFormSelector);
    const { approval_status: approvalStatus } = useSelector(businessFormSelector);
    const isAdmin = useSelector(isAdminSelector);

    const isLoadingStart = useProcessLoader({
        processNames: [GET_BUSINESS_FORM_PROCESS],
    });

    const addBusinessSections = addBusinessSectionsUtils.renderAddBusinessSections({
        intl,
        type,
        isAdmin,
    });

    if (isLoadingStart) {
        return null;
    }

    return (
        <FavePageMainMyBusinessContainer>
            <FavePageMainMyBusinessStatusTagContainer>
                <StatusTag status={approvalStatus} />
            </FavePageMainMyBusinessStatusTagContainer>
            {addBusinessSectionsUtils.renderAddBusinessSectionComponentById(
                addBusinessSections,
                currentSectionId,
            )}
            <FavePageMainSectionNavigation
                handleBackNavigation={handleBackNavigation}
                disableBackNavigation={disableBackNavigation}
                handleNextNavigation={handleNextNavigation}
                disableNextNavigation={disableNextNavigation}
            />
        </FavePageMainMyBusinessContainer>
    );
};

FavePageMainMyBusiness.propTypes = {
    currentSectionId: PropTypes.string.isRequired,
    handleBackNavigation: PropTypes.func,
    disableBackNavigation: PropTypes.bool,
    handleNextNavigation: PropTypes.func,
    disableNextNavigation: PropTypes.bool,
};

FavePageMainMyBusiness.defaultProps = {
    handleBackNavigation: null,
    disableBackNavigation: true,
    handleNextNavigation: null,
    disableNextNavigation: false,
};

export default FavePageMainMyBusiness;
