import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import headerTheme from '../../styles/headerTheme';

const HeaderOffsetContainer = styled.div`
    margin-top: ${({ theme, isVertical }) =>
        isVertical ? 0 : rem(get(theme, 'header.height', headerTheme.header.height))};
    position: relative;
    display: flex;
    flex-direction: column;

    ${({ bgStart }) =>
        bgStart &&
        css`
            background-color: ${bgStart};
        `};

    ${({ bgStart, bgEnd }) =>
        bgStart &&
        bgEnd &&
        css`
            background-image: linear-gradient(to bottom, ${bgStart} 0, ${bgEnd} 100%);
        `};

    ${({ isFullHeight }) =>
        isFullHeight &&
        css`
            min-height: 100vh;
        `}

    ${({ isBlurred, theme }) =>
        isBlurred &&
        css`
            &:before {
                backdrop-filter: blur(
                    ${rem(get(theme, 'header.filter.blur', headerTheme.header.filter.blur))}
                );
                content: '';
                height: 100%;
                left: 0;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: ${get(theme, 'header.zIndex', headerTheme.header.zIndex)};
            }
        `}

    ${({ isVertical, theme }) =>
        isVertical &&
        css`
            margin-left: ${rem(
                get(theme, 'header.vertical.width', headerTheme.header.vertical.width),
            )};
        `}
`;

export default HeaderOffsetContainer;
