import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { H4 } from 'baseline-ui/typography';
import { Button } from 'baseline-ui/button';

const RequestButton = styled(Button)`
    margin: 0.2rem;
`;
RequestButton.defaultProps = {
    type: 'button',
};

const EntityLinkRequestReceivedItem = ({
  name,
  date,
  comment,
  showAdd,
  showRemove,
  showExpand,
  expand,
  onExpand,
  onAdd,
  onRemove,
}) => {
  const text = comment.trim();
  const hidden = expand ? '' : 'hidden';
  return (
      <div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ flex: 1 }}>
                  <H4>{name ?? '-'}</H4>
                  <p>{date.toLocaleDateString()}</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'flex-start' }}>
                  {showAdd && (
                      <RequestButton onClick={onAdd}>
                          <FormattedMessage id="addBusiness.page.sections.people.requests.approve.button.label" />
                      </RequestButton>
                  )}
                  {showRemove && (
                      <RequestButton onClick={onRemove}>
                          <FormattedMessage id="addBusiness.page.sections.people.requests.reject.button.label" />
                      </RequestButton>
                  )}
                  {showExpand && (
                      <RequestButton
                          btnType="link"
                          icon={expand ? 'chevron-down' : 'chevron-up'}
                          onClick={onExpand}
                      />
                  )}
              </div>
          </div>
          <p hidden={hidden}>
              {text || (
                  <small>
                      <FormattedMessage id="addBusiness.page.sections.people.requests.nocomment" />
                  </small>
              )}
          </p>
      </div>
  );
};

EntityLinkRequestReceivedItem.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  comment: PropTypes.string,
  showAdd: PropTypes.bool,
  showRemove: PropTypes.bool,
  showExpand: PropTypes.bool,
  expand: PropTypes.bool,
  onExpand: PropTypes.func,
};

EntityLinkRequestReceivedItem.defaultProps = {
  comment: '',
  showAdd: false,
  showRemove: false,
  showExpand: false,
  expand: false,
  onExpand: () => {},
};

export default EntityLinkRequestReceivedItem