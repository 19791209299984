import React, { useMemo, useState } from 'react';
import { ENTITY_LINK_STATUS_APPROVED, ENTITY_LINK_STATUS_DENIED, ENTITY_LINK_STATUS_PENDING } from 'common/entityLinkStatus';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { H3, P } from 'baseline-ui/typography';
import { useDispatch } from 'react-redux';
import { UPDATE_ENTITY_LINKS_TO_ENTITY_CALL } from 'redux/actions/EntityLinkActions';
import EntityLinkRequestReceivedItem from './EntityLinkRequestReceivedItem';

const EntityLinkRequestReceivedList = (props) => {
    const { items, entityType } = props;
    const entityTypeLowerCase = entityType.toLowerCase()
    const dispatch = useDispatch();
    const { pending, accepted, denied } = useMemo(() => {
        const pending = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_PENDING);
        const accepted = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_APPROVED);
        const denied = items.filter((item) => item.approvalstatus === ENTITY_LINK_STATUS_DENIED);

        return {
            pending,
            accepted,
            denied,
        };
    }, [items]);
    const [openComment, setOpenComment] = useState(() => {
        return pending[0]?.id ?? accepted[0]?.id;
    });

    const approveRequest = (id) => {
        dispatch({
            type: UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                entityRequestedType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_APPROVED,
            },
        });
    };

    const removeRequest = (id) => {
        dispatch({
            type: UPDATE_ENTITY_LINKS_TO_ENTITY_CALL,
            payload: {
                entityRequestedType: entityType,
                entityLinkRequestsId: id,
                approvalstatus: ENTITY_LINK_STATUS_DENIED,
            },
        });
    };

    return (
        <SubsectionContainer>
            <Subsection>
                <SubsectionTitle>
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.pending.title`}
                    />
                </SubsectionTitle>
                {pending.length ? (
                    pending.map((req, idx) => {
                        const expand = (idx === 0 && !openComment) || openComment === req._id;
                        return (
                            <div key={req._id}>
                                <EntityLinkRequestReceivedItem
                                    id={req._id}
                                    name={req.requestingEntity.name}
                                    date={new Date(req.created)}
                                    comment={req.message}
                                    expand={expand}
                                    showAdd={true}
                                    showExpand
                                    showRemove={true}
                                    onExpand={() => setOpenComment(req._id)}
                                    onAdd={() => approveRequest(req._id)}
                                    onRemove={() => removeRequest(req._id)}
                                />
                            </div>
                        );
                    })
                ) : (
                    <P>
                        <small>
                            <FormattedMessage
                                id={`addBusiness.page.sections.${entityTypeLowerCase}.pending.empty`}
                            />
                        </small>
                    </P>
                )}
            </Subsection>
            <Subsection>
                <SubsectionTitle>
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.accepted.title`}
                    />
                </SubsectionTitle>
                {accepted.length ? (
                    accepted.map((req, idx) => {
                        const expand = (idx === 0 && !openComment) || openComment === req._id;
                        return (
                            <div key={req._id}>
                                <EntityLinkRequestReceivedItem
                                    id={req._id}
                                    name={req.requestingEntity.name}
                                    date={new Date(req.created)}
                                    comment={req.message}
                                    expand={expand}
                                    showExpand
                                    showRemove={true}
                                    onExpand={() => setOpenComment(req._id)}
                                    onRemove={() => removeRequest(req._id)}
                                />
                            </div>
                        );
                    })
                ) : (
                    <P>
                        <small>
                            <FormattedMessage
                                id={`addBusiness.page.sections.${entityTypeLowerCase}.accepted.empty`}
                            />
                        </small>
                    </P>
                )}
            </Subsection>
            <Subsection>
                <SubsectionTitle>
                    <FormattedMessage
                        id={`addBusiness.page.sections.${entityTypeLowerCase}.denied.title`}
                    />
                </SubsectionTitle>
                {denied.length ? (
                    denied.map((req, idx) => {
                        const expand = (idx === 0 && !openComment) || openComment === req._id;
                        return (
                            <div key={req._id}>
                                <EntityLinkRequestReceivedItem
                                    id={req._id}
                                    name={req.requestingEntity.name}
                                    date={new Date(req.created)}
                                    comment={req.message}
                                    expand={expand}
                                    showAdd={true}
                                    showExpand
                                    onExpand={() => setOpenComment(req._id)}
                                    onAdd={() => approveRequest(req._id)}
                                />
                            </div>
                        );
                    })
                ) : (
                    <P>
                        <small>
                            <FormattedMessage
                                id={`addBusiness.page.sections.${entityTypeLowerCase}.denied.empty`}
                            />
                        </small>
                    </P>
                )}
            </Subsection>
        </SubsectionContainer>
    );
};

const Subsection = styled.section`
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 3rem;
`;

const SubsectionContainer = styled.div``;

const SubsectionTitle = styled(H3)``;
SubsectionTitle.defaultProps = {
    color: 'secondary',
};

export default EntityLinkRequestReceivedList;
