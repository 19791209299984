import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewContentHeader = styled.div`
    align-items: left;
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(16)};
`;

export default FavePagePreviewContentHeader;
