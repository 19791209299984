import React, { useEffect } from 'react';
import { H5 } from 'baseline-ui/typography';
import { Col, Row } from 'baseline-ui/layout';
import { Field, Form, useFormikContext } from 'formik';
import { FieldContainer, FormRadioGroup, FormSelect } from 'baseline-ui/form';
import { Button } from 'baseline-ui/button';
import { useDispatch } from 'react-redux';
import {
    POST_BUSINESS_FORM_PROCESS,
    SET_BUSINESS_FORM_DATA_START,
} from 'redux/actions/BusinessFormActions';
import useProcessLoader from 'utils/useProcessLoader';

const AdminBusinessStatusForm = () => {
    const dispatch = useDispatch();
    const { values } = useFormikContext();

    useEffect(() => {
        dispatch({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: values,
        });
    }, [values]);

    const isLoading = useProcessLoader({
        processNames: [POST_BUSINESS_FORM_PROCESS],
    });

    const approvalOptions = [
        { value: null, label: 'admin.approvalStatus.label', disabled: true },
        {
            value: 'DRAFT',
            label: 'admin.approvalStatus.DRAFT.label',
        },
        {
            value: 'PENDING',
            label: 'admin.approvalStatus.PENDING.label',
        },
        {
            value: 'APPROVED',
            label: 'admin.approvalStatus.APPROVED.label',
        },
        {
            value: 'DENIED',
            label: 'admin.approvalStatus.DENIED.label',
        },
        {
            value: 'NO_PHOTOS',
            label: 'admin.approvalStatus.NO_PHOTOS.label',
        },
        {
            value: 'OUT_OF_BUSINESS',
            label: 'admin.approvalStatus.OUT_OF_BUSINESS.label',
        },
    ];

    const followupStatusOptions = [
        { value: null, label: 'admin.followupStatus.label', disabled: true },
        {
            value: 'LEFT_MESSAGE',
            label: 'admin.followupStatus.LEFT_MESSAGE.label',
        },
        {
            value: 'PITCHED',
            label: 'admin.followupStatus.PITCHED.label',
        },
        {
            value: 'POPULATED',
            label: 'admin.followupStatus.POPULATED.label',
        },
        {
            value: 'ADVERTISE',
            label: 'admin.followupStatus.ADVERTISE.label',
        },
        {
            value: 'BETA',
            label: 'admin.followupStatus.BETA.label',
        },
    ];

    return (
        <Form noValidate>
            <Row>
                <Col xxs={30}>
                    <H5 color="secondary">Admin</H5>
                </Col>
            </Row>
            <div>
                <Row>
                    <Col xxs={30} xs={20} sm={16} md={10} xl={8}>
                        <FieldContainer>
                            <Field
                                component={FormSelect}
                                id="approval_status"
                                name="approval_status"
                                label="Approval"
                                options={approvalOptions}
                            />
                        </FieldContainer>
                    </Col>
                    <Col xxs={30} xs={20} sm={16} md={10} xl={8}>
                        <FieldContainer>
                            <Field
                                component={FormSelect}
                                id="followupStatus"
                                name="adminOnly.followupStatus"
                                label="Follow-up Status"
                                options={followupStatusOptions}
                            />
                        </FieldContainer>
                    </Col>
                    <Col xxs={30} xs={20} sm={16} md={10} xl={8}>
                        <Field
                            component={FormRadioGroup}
                            id="maxNotificationsPerWeek"
                            name="adminOnly.maxNotificationsPerWeek"
                        >
                            <Field
                                component={FormSelect}
                                id="maxNotificationsPerWeek"
                                name="adminOnly.maxNotificationsPerWeek"
                                translateOptionsLabel={false}
                                options={[
                                    {
                                        value: '0',
                                        label: 'Disabled',
                                    },
                                    {
                                        value: '1',
                                        label: '1',
                                    },
                                    {
                                        value: '2',
                                        label: '2',
                                    },
                                    {
                                        value: '3',
                                        label: '3',
                                    },
                                    {
                                        value: '4',
                                        label: '4',
                                    },
                                    {
                                        value: '5',
                                        label: '5',
                                    },
                                    {
                                        value: '1000',
                                        label: '1000',
                                    },
                                ]}
                                label="Notifications Per Week"
                            />
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col xxs={30} xs={30} sm={30} md={30} xl={30} jusitfyContent="flex-end">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                skin="secondary"
                                loading={isLoading}
                                loadingMessage="Confirming"
                                style={{ marginTop: '0.4rem' }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Form>
    );
};

export default AdminBusinessStatusForm;
