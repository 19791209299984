import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: ${rem(750)};
    ${({width}) => {
    return (
        width &&
            css`
                width: 100%;
                ${media.md`
                     width: ${width}%;
                `};
          `);
    }}

    ${media.md`
        padding-bottom: ${rem(8)};
        margin: 0 auto;
    `};
`;
export default TitleWrapper;