import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const AboutContentWrapper = styled.div`
    background-color: ${({ theme, bgWhite }) => bgWhite ? theme.site.welcome.bgEnd : theme.site.welcome.bg};
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: ${rem(68)};
    padding-left: ${rem(16)};
    padding-right: ${rem(16)};
    padding-top: ${rem(16)};
    margin-top: 0;
    ${media.sm`
        margin-top: 0;
    `}
    ${media.md`
        flex-direction: row;
        align-items: flex-start;
        border-radius: unset;
        line-height: ${rem(36)};
        margin-top: 0;
        padding-bottom: ${rem(76)};
        padding-left: ${rem(35)};
        padding-right: ${rem(43)};
        justify-content: center;

         & span:nth-child(1) {
            flex: 2 2 2;
        }
        & div:nth-child(2) {
            flex: 1 1 1;
            min-width: 480px;
        }
    `}
`;

export default AboutContentWrapper;