import { getEntityTopCategory, putEntityTopCategory, TagSearchCrumbs } from 'api';
import { Button } from 'baseline-ui/button';
import { FormInput } from 'baseline-ui/form';
import FormInputAutocompleteItem from 'baseline-ui/form/src/components/input/FormInputAutocompleteItem';
import { Col, Row } from 'baseline-ui/layout';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import CommonWrapper from 'screens/CommonWrapper';

const SUPPORTED_ENTITY_TYPES = [
    'entity_destination',
    'entity_business',
    'entity_product',
    'entity_people',
    'entity_movie',
    'entity_book',
    'entity_show',
    'entity_recipe',
    'entity_event',
];

const EntityMappingConfiguration = () => {
    const [entityAutCompleteItems, setEntityAutocompleteItems] = useState({});
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        const getAsync = async () => {
            const [res] = await getEntityTopCategory();
            console.log(res);
            setInitialValues(res);
        };

        getAsync();
    }, []);

    const getFieldAutocompleteItems = async (entityType, val) => {
        const [data] = await TagSearchCrumbs(val);
        const newEntityAutoCompleteItems = { ...entityAutCompleteItems };
        newEntityAutoCompleteItems[entityType] = data;
        setEntityAutocompleteItems(newEntityAutoCompleteItems);
    };

    return (
        <CommonWrapper>
            <Col>
                <div>Entity Mapping Configuration</div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        console.log(values);
                        const form = new FormData();

                        form.append('categoryMappingConfig', JSON.stringify(values));

                        putEntityTopCategory(form);
                    }}
                >
                    {({ setFieldValue, handleSubmit }) => {
                        return (
                            <>
                                {SUPPORTED_ENTITY_TYPES.map((entityType) => {
                                    return (
                                        <Row>
                                            <Field
                                                component={FormInput}
                                                label={entityType}
                                                name={entityType}
                                                id={entityType}
                                                type="text"
                                                // innerRef={ref}
                                                placeholder=""
                                                required={false}
                                                autoComplete="off"
                                                autocompleteItems={
                                                    entityAutCompleteItems[entityType] ?? []
                                                }
                                                autocompleteItemRender={(item) => {
                                                    return (
                                                        <FormInputAutocompleteItem
                                                            skin="dark"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    entityType,
                                                                    item.reverse()[0]._id,
                                                                );
                                                                setEntityAutocompleteItems({
                                                                    ...entityAutCompleteItems,
                                                                    [entityType]: [],
                                                                });
                                                            }}
                                                        >
                                                            {item.reverse().map((i, idx) => (
                                                                <>
                                                                    {i.name} ({i.type}){' '}
                                                                    {idx === item.length - 1
                                                                        ? ''
                                                                        : '-> '}
                                                                </>
                                                            ))}
                                                        </FormInputAutocompleteItem>
                                                    );
                                                }}
                                                onBlur={() => {
                                                    setFieldValue(entityType, '');
                                                    // The autocomplete items are reset after 300ms to avoid race condition between onClick event and onBlur.
                                                    // This probably could be solved by using useEffects, but this is a decent UX as well as simpler to read than a ton of useEffects
                                                    setTimeout(() => {
                                                        setEntityAutocompleteItems({
                                                            ...entityAutCompleteItems,
                                                            [entityType]: [],
                                                        });
                                                    }, 300);
                                                }}
                                                onChange={(e) => {
                                                    getFieldAutocompleteItems(
                                                        entityType,
                                                        e.target.value,
                                                    );
                                                    setFieldValue(entityType, e.target.value);
                                                }}
                                            />
                                        </Row>
                                    );
                                })}
                                <Button onClick={handleSubmit}>Submit</Button>
                            </>
                        );
                    }}
                </Formik>
            </Col>
        </CommonWrapper>
    );
};

export default EntityMappingConfiguration;
