import styled from 'styled-components';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

const ContentContainer = styled.div`
    display: flex;  
    padding-bottom: ${rem(16)};
    flex-direction: column-reverse;
    
    ${media.md`
        flex-direction: unset;
    `}
`;

export default ContentContainer;
