import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';
import formInputFillStyles from '../../styles/formInputFillStyles';

const FormInputLabel = styled.span`
    color: ${({ skin, theme }) =>
        get(theme, `form.label.${skin}.color`, formTheme.form.label[skin].color)};
    display: inline-block;
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.label.fontSize', formTheme.form.label.fontSize))};
    left: ${rem(12)};
    overflow: hidden;
    padding-right: ${rem(16)};
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: ${rem(16)};
    transition: 0.3s ease all;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    ${({ centered }) =>
        centered &&
        css`
            text-align: center;
        `}

    ${({ requiredValue, showIsRequired }) =>
        requiredValue &&
        showIsRequired &&
        css`
            :after {
                content: '*';
            }
        `}

    ${({ focused, filled }) =>
        (focused || filled) &&
        css`
            ${formInputFillStyles}
        `}

    ${({ filled, skin, theme }) =>
        filled &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorFilled`,
                formTheme.form.label[skin].colorFilled,
            )};
        `}

    ${({ focused, skin, theme }) =>
        focused &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorFocus`,
                formTheme.form.label[skin].colorFocus,
            )};
        `}

    ${({ disabledValue, skin, theme }) =>
        disabledValue &&
        css`
            color: ${get(
                theme,
                `form.label.${skin}.colorDisabled`,
                formTheme.form.label[skin].colorDisabled,
            )};
        `}

    ${({ error, isSubmitted, theme }) =>
        (error || (error && isSubmitted)) &&
        css`
            color: ${get(theme, 'form.error.color', formTheme.form.error.color)};
        `}
`;

export default FormInputLabel;
