import styled from 'styled-components';
import siteBaseTheme from 'styles/siteBaseTheme';
import {rem} from "polished";

const MenuOverlay = styled.div`
  position: fixed;
  top: ${rem(70)};
  left: 0;
  width: 100%;
  height: calc(100vh - ${rem(80)});
  background-color: ${({ skin }) => (skin && `${siteBaseTheme.base.colors[skin]}`)};
  display: flex;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease;
  z-index: 10;
  border-top: 1px solid ${siteBaseTheme.base.grays.grayLightest};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default MenuOverlay;