import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { typographyTheme, typographyUtils } from 'baseline-ui/typography';
import { has } from 'lodash';
import Label, { LabelStyleProps } from 'components/Label';

const Badge = ({ icon, title, ...props }) => {
    return (
        <BadgeContent {...props}>
            {icon && <img src={icon} />}
            {title}
        </BadgeContent>
    );
};

const BadgeContent = styled(Label)`
    align-items: center;
    ${({ bgColor }) =>
        bgColor &&
        css`
            background-color: ${bgColor};
        `}
    color: ${({ textColor }) => typographyTheme.typography.colors[textColor]};
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    ${(props) =>
        has(props, 'iconTitleGap') &&
        css`
            gap: ${typographyUtils.processSize(props.iconTitleGap)};
        `}

    & img {
        ${(props) =>
            has(props, 'iconWidth') &&
            css`
                width: ${typographyUtils.processSize(props.iconWidth)};
            `}
        ${(props) =>
            has(props, 'iconHeight') &&
            css`
                height: ${typographyUtils.processSize(props.iconHeight)};
            `}
    }
`;

export const badgeStyleProps = {
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    iconTitleGap: PropTypes.number,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    ...LabelStyleProps,
};

Badge.PropTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    ...badgeStyleProps,
};

export default Badge;
