import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import BackgroundBase from './BackgroundBase'
import heartHalfLeft from '../images/heart-half-side-left.svg';
import heartLeft from '../images/heart-side-left.svg';
import heartHalfRight from '../images/heart-half-side-right.svg';

const BackgroundHeartHalfSideSpaceBetween = styled(BackgroundBase)`
    &::before {
        content: unset;
        background-repeat: no-repeat;
        background-position: left center;
        position: absolute;
        background-image: url(${heartHalfLeft});
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        ${media.md`
          background-position: left bottom;
          content: '';
          width: 50%;
        `}
    }

    &::after {
        content: '';
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url(${heartLeft});
        position: absolute;
        top: 0;
        right: -80px;
        width: 100%;
        height: 100%;
        z-index: 0;
        ${media.md`
          background-image: url(${heartHalfRight});
          background-position: right bottom;
          content: '';
          width: 50%;
        `}
    }
`;

export default BackgroundHeartHalfSideSpaceBetween