import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {media} from '../baseline-ui/layout';

const Container = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${({ bgColor }) => css`
        background-color: ${bgColor};
    `}
    ${({ padding }) =>
    padding &&
    css`
        padding-bottom: ${rem(56)};
        padding-left: ${rem(40)};
        padding-right: ${rem(40)};
        padding-top: ${rem(56)};
    `}
    
    ${media.lg`
       text-align: left;
       justify-content: left;
       align-items: normal;
   `};
`;

export default Container;