import styled from 'styled-components';
import { rem } from 'polished';

const SigninFooter = styled.div`
    display: flex;
    width: 100%;
    margin-top: ${rem(16)};
    padding: ${rem(16)} 0;
    align-items: center;
`;

export const SigninFooterMobile = styled(SigninFooter)`
    flex-direction: column;
    gap: ${rem(18)};
    justify-content: center;
`

export const SigninFooterDesktop = styled(SigninFooter)`
    flex-direction: row;
    gap: ${rem(24)};
    justify-content: flex-end
`