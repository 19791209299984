import styled from 'styled-components';
import {Form} from 'formik';
import {media} from 'baseline-ui/layout';

const CustomForm = styled(Form)`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    
     ${media.md`
         align-items: center;
    `};
`;
export default CustomForm;