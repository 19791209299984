import styled, { css } from 'styled-components';
import { has } from 'lodash';
import { typographyTheme, typographyUtils } from 'baseline-ui/typography';
import PropTypes from 'prop-types'

const Label = styled.span`
    ${({ display }) => display && css`display: ${display};`}
    color: ${({ skin }) => typographyTheme.typography.colors[skin]};
    font-family: ${({fontFamily}) => fontFamily ? fontFamily : typographyTheme.typography.base.fontFamily};
    ${({ underline }) => underline && css`text-decoration: underline;`}
    ${({ textAlign }) => textAlign && css`text-align: ${textAlign};`}
    ${(props) => has(props, 'fontWeight') && css`font-weight: ${props.fontWeight};`}
    ${(props) => has(props, 'fontSize') && css`font-size: ${typographyUtils.processSize(props.fontSize)};`}
    ${(props) => has(props, 'lineHeight') && css`line-height: ${typographyUtils.processSize(props.lineHeight)};`}
    ${(props) => has(props, 'maxWidth') && css`max-width: ${typographyUtils.processSize(props.maxWidth)};`}
    margin-top: ${({ margin }) => has(margin, 'top') ? typographyUtils.processSize(margin.top) : 0};
    margin-right: ${({ margin }) => has(margin, 'right') ? typographyUtils.processSize(margin.right) : 0};
    margin-bottom: ${({ margin }) => has(margin, 'bottom') ? typographyUtils.processSize(margin.bottom) : 0};
    margin-left: ${({ margin }) => has(margin, 'left') ? typographyUtils.processSize(margin.left) : 0};
    padding-top: ${({ padding }) => has(padding, 'top') ? typographyUtils.processSize(padding.top) : 0};
    padding-right: ${({ padding }) => has(padding, 'right') ? typographyUtils.processSize(padding.right) : 0};
    padding-bottom: ${({ padding }) => has(padding, 'bottom') ? typographyUtils.processSize(padding.bottom) : 0};
    padding-left: ${({ padding }) => has(padding, 'left') ? typographyUtils.processSize(padding.left) : 0};
    ${({ border }) => css`
            ${has(border, 'top.size') && `border-top-width: ${typographyUtils.processSize(border.top.size)};`}
            ${has(border, 'top.style') && `border-top-style: ${border.top.style};`}
            ${has(border, 'top.color') && `border-top-color: ${border.top.color};`}
            ${has(border, 'right.size') && `border-right-width: ${typographyUtils.processSize(border.right.size)};`}
            ${has(border, 'right.style') && `border-right-style: ${border.right.style};`}
            ${has(border, 'right.color') && `border-right-color: ${border.right.color};`}
            ${has(border, 'bottom.size') && `border-bottom-width: ${typographyUtils.processSize(border.bottom.size)};`}
            ${has(border, 'bottom.style') && `border-bottom-style: ${border.bottom.style};`}
            ${has(border, 'bottom.color') && `border-bottom-color: ${border.bottom.color};`}
            ${has(border, 'left.size') && `border-left-width: ${typographyUtils.processSize(border.left.size)};`}
            ${has(border, 'left.style') && `border-left-style: ${border.left.style};`}
            ${has(border, 'left.color') && `border-left-color: ${border.left.color};`}
        `
    }
    ${({ borderRadius }) => css`
            ${has(borderRadius, 'topRight') && `border-top-right-radius: ${typographyUtils.processSize(borderRadius.topRight)};`}
            ${has(borderRadius, 'topLeft') && `border-top-left-radius: ${typographyUtils.processSize(borderRadius.topLeft)};`}
            ${has(borderRadius, 'bottomRight') && `border-bottom-right-radius: ${typographyUtils.processSize(borderRadius.bottomRight)};`}
            ${has(borderRadius, 'bottomLeft') && `border-bottom-left-radius: ${typographyUtils.processSize(borderRadius.bottomLeft)};`}
        `
    }
`;

export const LabelStyleProps = {
    display: PropTypes.string,
    skin: PropTypes.string,
    underline: PropTypes.string,
    textAlign: PropTypes.string,
    fontWeight: PropTypes.number,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    padding: PropTypes.shape({
        top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    border: PropTypes.shape({
        top: {
            size: PropTypes.number,
            style: PropTypes.string,
            color: PropTypes.string
        },
        right: {
            size: PropTypes.number,
            style: PropTypes.string,
            color: PropTypes.string
        },
        bottom: {
            size: PropTypes.number,
            style: PropTypes.string,
            color: PropTypes.string
        },
        left: {
            size: PropTypes.number,
            style: PropTypes.string,
            color: PropTypes.string
        }
    }),
    borderRadius: PropTypes.shape({
        topRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        topLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        bottomRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        bottomLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
}

export default Label;
