import styled from 'styled-components';
import {media} from 'baseline-ui/layout';

const ParagraphWrapper = styled.div`
    text-align: center;
  
   ${media.md`
        text-align: left;
   `};
`;

export default ParagraphWrapper;