import {
    POST_DOWNLOAD_APP_FORM_DATA_ERROR,
    POST_DOWNLOAD_APP_FORM_DATA_PROCESS,
    POST_DOWNLOAD_APP_FORM_DATA_START,
    POST_DOWNLOAD_APP_FORM_DATA_SUCCESS,
} from '../actions/DownloadFavePageAppFormActions';
import genericSagaHandler from './CommonSaga';
import { takeEvery, put } from 'redux-saga/effects';
import {postDownloadFavePage} from 'api';

function* postDownloadFavePageSaga({ payload }) {
    yield genericSagaHandler(POST_DOWNLOAD_APP_FORM_DATA_PROCESS, function* () {
        try{
            yield postDownloadFavePage({
                firstName: payload.firstName,
                lastName: payload.lastName,
                email: payload.emailAddress,
                phone: payload.phoneNumber,
                zipCode: payload.zipCode,
                device: payload.device,
                accountEmailAddress: payload.accountEmailAddress,
            });

            yield put({
                type: POST_DOWNLOAD_APP_FORM_DATA_SUCCESS,
            });
        }
        catch (error){
            yield put({
                type: POST_DOWNLOAD_APP_FORM_DATA_ERROR,
                payload: error
            });
        }
    });
}

export default function* DownloadFavePageAppFormSagas() {
    yield takeEvery(POST_DOWNLOAD_APP_FORM_DATA_START, postDownloadFavePageSaga);
}