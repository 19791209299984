import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewContentSectionContainer = styled.div`
    margin-bottom: ${rem(8)};
    display: flex; 
    gap: 4px
`;

export default FavePagePreviewContentSectionContainer;
