import React from 'react';
import DesktopView from './DesktopView';
import MobileTabletView from './MobileTabletView';

export default function WhatIsFavePage() {
    return (
        <>
            <DesktopView />
            <MobileTabletView />
        </>
    );
}
