import styled from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import { typographyTheme } from 'baseline-ui/typography';
import formTheme from '../../styles/formTheme';

const FormInputAutocompleteItem = styled.div`
    color: ${({ skin, theme }) =>
        get(theme, `form.label.${skin}.color`, formTheme.form.label[skin].color)};
    font-family: ${({ theme }) =>
        get(theme, 'typography.base.fontFamily', typographyTheme.typography.base.fontFamily)};
    font-size: ${({ theme }) =>
        rem(get(theme, 'form.label.fontSize', formTheme.form.label.fontSize))};
`;

export default FormInputAutocompleteItem;
