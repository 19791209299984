import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, buttonSkinData, buttonBtnTypeData } from 'baseline-ui/button';
import { H2, H5, P } from 'baseline-ui/typography';
import {
    FormInput,
    FormSelect,
    FormTextarea,
    FieldContainer,
    FormMaskedInput,
    FieldsGrouped,
    FieldGroupedContainer,
    FormRadio,
} from 'baseline-ui/form';
import { TransitionItem } from 'baseline-ui/helpers';
import { AlertFeedback, alertPropTypes } from 'baseline-ui/alert';
import {
    AuthFormContainer,
    AuthFormSignError,
    AuthHeadingContainer,
    authPropTypes,
    AuthFootnote,
    AuthFieldHeaderContainer,
} from 'baseline-ui/auth';
import formInquireSchema from 'schema/formInquireSchema';
import { FLEX_DIRECTION, MOBILE_PHONE_NUMBER_MASK } from 'common/consts';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import ButtonWrapper from 'baseline-ui/button/src/components/ButtonWrapper';

const FormInquire = ({
    onSubmitInquire,
    isLoading,
    errorMessage,
    buttonSkin,
    buttonBtnType,
    showHeading,
    inProp,
    inPropFeedback,
    feedbackType,
    feedbackMessage,
    footnote,
    appear,
    inquireTypeOptions,
}) => {
    const intl = useIntl();
    const { state } = useLocation();
    const inquiryTypeOptionFromState = get(state, 'inquiryTypeOption', '');

    const submitInquire = (values) => {
        onSubmitInquire(values);
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        businessName: '',
        emailAddress: '',
        phoneNumber: '',
        inquireTypeOptions: inquiryTypeOptionFromState,
        inquire: '',
    };

    const renderHeading = (
        <AuthHeadingContainer>
            <H2>
                <FormattedMessage id="formInquiry.yourInquire.heading.title" />
            </H2>
            <P>
                <FormattedMessage id="formInquiry.yourInquire.heading.content" />
            </P>
        </AuthHeadingContainer>
    );

    return (
        <AuthFormContainer maxWidth={640}>
            <AlertFeedback
                inProp={inPropFeedback}
                alertType={feedbackType}
                alertMessage={feedbackMessage}
            />
            <TransitionItem inProp={inProp} appear={appear}>
                {showHeading && renderHeading}
                <Formik
                    initialValues={initialValues}
                    validationSchema={formInquireSchema}
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        submitInquire(values);
                    }}
                >
                    {({ setFieldValue }) => {
                        return (
                            <Form noValidate>
                                <AuthFieldHeaderContainer>
                                    <H5>
                                        <FormattedMessage id="formInquiry.yourInquire.heading.aboutYou.subtitle" />
                                    </H5>
                                </AuthFieldHeaderContainer>
                                <FieldsGrouped direction_xs={FLEX_DIRECTION.ROW}>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formInquiry.form.firstName.label',
                                                })}
                                                name="firstName"
                                                id="firstName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formInquiry.form.lastName.label',
                                                })}
                                                name="lastName"
                                                id="lastName"
                                                type="text"
                                                disabled={isLoading}
                                                required
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>
                                <FieldsGrouped direction_xs={FLEX_DIRECTION.ROW}>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormInput}
                                                label={intl.formatMessage({
                                                    id: 'formInquiry.form.businessName.label',
                                                })}
                                                name="businessName"
                                                id="businessName"
                                                type="text"
                                                disabled={isLoading}
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                    <FieldGroupedContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormMaskedInput}
                                                label={intl.formatMessage({
                                                    id: 'formInquiry.form.phoneNumber.label',
                                                })}
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                disabled={isLoading}
                                                required
                                                mask={MOBILE_PHONE_NUMBER_MASK}
                                                onAccept={(value, mask) => {
                                                    mask.updateValue();
                                                    setFieldValue('phoneNumber', value);
                                                }}
                                            />
                                        </FieldContainer>
                                    </FieldGroupedContainer>
                                </FieldsGrouped>

                                <FieldContainer paddingBottom={34}>
                                    <Field
                                        component={FormInput}
                                        label={intl.formatMessage({
                                            id: 'formInquiry.form.emailAddress.label',
                                        })}
                                        name="emailAddress"
                                        id="emailAddress"
                                        type="email"
                                        disabled={isLoading}
                                        required
                                    />
                                </FieldContainer>

                                {inquireTypeOptions && (
                                    <>
                                        <AuthFieldHeaderContainer>
                                            <H5>
                                                <FormattedMessage id="formInquiry.yourInquire.heading.yourInquire.subtitle" />
                                            </H5>
                                        </AuthFieldHeaderContainer>
                                        <FieldContainer>
                                            <Field
                                                component={FormSelect}
                                                id="inquireTypeOptions"
                                                name="inquireTypeOptions"
                                                label={intl.formatMessage({
                                                    id: 'formInquiry.form.typeOfInquire.options.label',
                                                })}
                                                options={inquireTypeOptions}
                                                required
                                            />
                                        </FieldContainer>
                                    </>
                                )}
                                <FieldContainer>
                                    <Field
                                        component={FormTextarea}
                                        label={intl.formatMessage({
                                            id: 'formInquiry.form.yourInquire.label',
                                        })}
                                        name="inquire"
                                        id="inquire"
                                        disabled={isLoading}
                                        required
                                    />
                                </FieldContainer>
                                <ButtonWrapper width={310} marginY={20}>
                                    <Button
                                        skin={buttonSkin}
                                        btnType={buttonBtnType}
                                        type="submit"
                                        block
                                        loading={isLoading}
                                        loadingMessage={intl.formatMessage({
                                            id: 'formInquiry.form.submitInquire.loading.label',
                                        })}
                                        size="lg"
                                    >
                                        <FormattedMessage id="formInquiry.form.submitInquire.label" />
                                    </Button>
                                </ButtonWrapper>
                                <AuthFormSignError errorMessage={errorMessage} />
                                <AuthFootnote footnote={footnote} />
                            </Form>
                        );
                    }}
                </Formik>
            </TransitionItem>
        </AuthFormContainer>
    );
};

FormInquire.propTypes = {
    onSubmitInquire: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errorMessage: authPropTypes.authErrorMessage,
    buttonSkin: PropTypes.oneOf(buttonSkinData),
    buttonBtnType: PropTypes.oneOf(buttonBtnTypeData),
    showHeading: PropTypes.bool,
    inProp: PropTypes.bool,
    inPropFeedback: PropTypes.bool,
    feedbackType: alertPropTypes.type,
    feedbackMessage: PropTypes.node,
    footnote: PropTypes.node,
    appear: PropTypes.bool,
    inquireTypeOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            disable: PropTypes.bool,
        }),
    ),
};

FormInquire.defaultProps = {
    isLoading: false,
    errorMessage: null,
    buttonSkin: 'primary',
    buttonBtnType: 'default',
    showHeading: true,
    inProp: false,
    inPropFeedback: false,
    feedbackType: undefined,
    feedbackMessage: undefined,
    footnote: undefined,
    appear: true,
    inquireTypeOptions: undefined,
};

export default FormInquire;
