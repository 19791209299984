import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewFavesButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${rem(16)};
`;

export default FavePagePreviewFavesButtonsContainer;
