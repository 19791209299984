import styled from 'styled-components';
import { get, has } from 'lodash';
import { rem } from 'polished';
import h1Styles from '../styles/h1Styles';
import typographyTheme from '../styles/typographyTheme';
import {media} from 'baseline-ui/layout';
import typographyUtils from '../utils/typographyUtils';

const ExtraLargeH1 = styled.div`
    ${h1Styles}

    display: block;
    font-size: ${({ theme, fontSize }) =>
    fontSize ? rem(fontSize/1.3)
    : rem(
        get(
            theme,
            'typography.largeHeadings.fontSize.h1',
            (typographyTheme.typography.xLargeHeadings.fontSize.h1 / 1.5),
        ),
    )};
    font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? fontWeight
    : get(
        theme,
        'typography.largeHeadings.fontWeight.h1',
        typographyTheme.typography.xLargeHeadings.fontWeight.h1,
    )};
    line-height: ${({ theme }) =>
    get(
        theme,
        'typography.largeHeadings.lineHeight.h1',
        typographyTheme.typography.xLargeHeadings.lineHeight.h1,
    )};
    padding: ${({ theme }) =>
    rem(
        get(
            theme,
            'typography.largeHeadings.paddingV.h1',
            typographyTheme.typography.xLargeHeadings.paddingV.h1,
        ),
    )}
        0;
        ${media.md`
         font-size: ${({ theme, fontSize }) =>
            fontSize ? rem(fontSize)
                : rem(
                    get(
                        theme,
                        'typography.largeHeadings.fontSize.h1',
                        typographyTheme.typography.xLargeHeadings.fontSize.h1,
                    ),
            )};
    `};
    margin-top: ${({margin}) => {
        if(has(margin, 'top')) {
            return typographyUtils.processSize(margin.top)
        }

        return 0
    }};
    margin-right: ${({margin}) => {
        if(has(margin, 'right')) {
            return typographyUtils.processSize(margin.right)
        }

        return 0
    }};
    margin-bottom: ${({margin}) => {
        if(has(margin, 'bottom')) {
            return typographyUtils.processSize(margin.bottom)
        }

        return 0
    }};
    margin-left: ${({margin}) => {
        if(has(margin, 'left')) {
            return typographyUtils.processSize(margin.left)
        }

        return 0
    }};
`;

export default ExtraLargeH1;
