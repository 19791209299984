import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { rem } from 'polished';
import footerTheme from 'baseline-ui/footer/src/styles/footerTheme';
import siteBaseTheme from 'styles/siteBaseTheme';
import {baseTheme} from 'baseline-ui/base-theme';

const FooterContentWrapper = styled.footer`
    background-color: ${({ backgroundColor }) =>
    backgroundColor
        ?  `${baseTheme.base.colors[backgroundColor]}`
        : 
           `${footerTheme.footer.defaultFooter.backgroundStart}`
        };
    padding: ${rem(16)} 0 ${rem(4)};
    position: relative;
    width: 100%;
    z-index: 1;
    border-top: ${rem(0.5)} solid ${siteBaseTheme.base.grays.grayLightest};
    ${({ showDivider, theme }) =>
        showDivider &&
        css`
            border-top: 1px solid
                ${get(
                    theme,
                    'footer.defaultFooter.dividerColor',
                    footerTheme.footer.defaultFooter.dividerColor,
                )};
        `}
`;

export default FooterContentWrapper;
