import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    TierHeader,
    TierItemComponent,
    TierType,
    TierListComponent,
    TierBusy,
    TierFlexPlaceholder,
    HorizontalSpacer,
} from './components';
import {
    TierAddButtons,
    TierControls,
    TierEditableLabel,
    TierEditableName,
    TierExpandButton,
} from './tiercontrols';
import { WithTiers, getValidChildTypes } from './helpers';

export function TierItem({ tier, withTiers, showHidden }) {
    // eslint-disable-next-line react/prop-types
    const { _id, name, label, type, expanded, busy, editing } = tier;
    const validChildTypes = getValidChildTypes(type);
    const expandable = validChildTypes.length > 0;
    const onExpand = useCallback(() => {
        if (expanded) {
            withTiers.collapseAllChildren(_id);
        } else {
            withTiers.toggleExpand(_id);
            withTiers.populateAsync(_id, showHidden);
        }
    }, [withTiers, _id, expanded]);
    const hasTiers = !!tier?.resources?.length;
    const disableControls = !!busy;
    const onAdd = (evt, addType) => {
        withTiers.add(_id, addType, {});
    };
    const onSave = async () => {
        const { working, ...rest } = tier;
        const item = {
            ...rest,
            ...working,
        };
        console.log('Saving item:', item);
        withTiers.saveItemAsync(item);
    };
    return (
        <TierItemComponent>
            <TierHeader>
                <TierExpandButton
                    id={`tier_expand_${_id}`}
                    onExpand={onExpand}
                    expanded={expanded}
                    expandable={expandable}
                />
                <TierEditableName
                    htmlFor={`tier_expand_${_id}`}
                    onClick={() => withTiers.setEditing(_id, true)}
                    editing={editing}
                    tier={tier}
                    withTiers={withTiers}
                    expandable={expandable}
                />
                {editing && <HorizontalSpacer />}
                <TierEditableLabel
                    htmlFor={`tier_expand_label_${_id}`}
                    onClick={() => withTiers.setEditing(_id, true)}
                    editing={editing}
                    tier={tier}
                    withTiers={withTiers}
                    expandable={expandable}
                />
                <TierType>
                    <FormattedMessage id={`superAdmin.tiers.levels.${type}.title`} />
                </TierType>
                {busy && <TierBusy />}
                {/* Commented out the placeholder as it was requested to make the display less wide */}
                {/* <TierFlexPlaceholder /> */}
                <TierControls
                    hidden={tier.hidden}
                    show={editing}
                    disabled={disableControls}
                    save={editing}
                    discard={editing}
                    edit={!editing}
                    onSave={onSave}
                    onHide={() => {
                        withTiers.saveItemAsync({ ...tier, hidden: true });
                    }}
                    onUnhide={() => {
                        withTiers.saveItemAsync({ ...tier, hidden: false });
                    }}
                    onDiscard={() => {
                        withTiers.update(_id, {
                            working: '',
                        });
                        withTiers.setEditing(_id, false);
                    }}
                    onEdit={() => withTiers.setEditing(_id, true)}
                />
            </TierHeader>
            {expandable && (
                <TierListComponent>
                    {expanded && (
                        <TierAddButtons
                            tier={tier}
                            validChildTypes={validChildTypes}
                            onAdd={onAdd}
                        />
                    )}
                    {expanded &&
                        (hasTiers ? (
                            tier.resources.map((resource) => (
                                <TierItem
                                    key={resource._id}
                                    tier={resource}
                                    withTiers={withTiers}
                                    showHidden={showHidden}
                                />
                            ))
                        ) : (
                            <TierNoItems />
                        ))}
                </TierListComponent>
            )}
        </TierItemComponent>
    );
}
TierItem.propTypes = {
    tier: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        expanded: PropTypes.bool,
        resources: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string.isRequired,
                busy: PropTypes.number,
                // eslint-disable-next-line react/forbid-prop-types
                working: PropTypes.any,
            }),
        ),
        type: PropTypes.oneOf(['group', 'category', 'subcategory', 'filter', 'header']),
    }).isRequired,
    withTiers: PropTypes.instanceOf(WithTiers).isRequired,
};

export function TierNoItems() {
    return (
        <TierItemComponent>
            <TierHeader>
                <i>
                    <FormattedMessage id="superAdmin.tiers.noItems" />
                </i>
            </TierHeader>
        </TierItemComponent>
    );
}
