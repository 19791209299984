import * as yup from 'yup';
import { fieldSchema, validationMessages } from 'baseline-ui/validation';

const formSignUpSchema = yup.object().shape({
    firstName: fieldSchema.firstName.required(),
    lastName: fieldSchema.lastName.required(),
    emailAddress: fieldSchema.email.required(),
    confirmEmailAddress: fieldSchema.email.required().oneOf([yup.ref('emailAddress'), null], validationMessages.confirmEmail.sameAs),
    password: fieldSchema.password.required(),
    confirmPassword: fieldSchema.passwordConfirm.required(),
});

export default formSignUpSchema;
