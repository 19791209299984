import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'baseline-ui/modal';
import { useIntl } from 'react-intl';
import { ContentRenderer } from 'baseline-ui/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
    DELETE_ENTITY_LINK_CALL,
    DELETE_ENTITY_LINK_RESET,
    RESEND_ENTITY_LINK_REQUEST_CALL,
    RESEND_ENTITY_LINK_REQUEST_RESET,
} from 'redux/actions/EntityLinkActions';
import { Small } from 'baseline-ui/typography';
import { ENTITY_LINK_STATUS_PENDING } from 'common/entityLinkStatus';
import { ENTITY_LINK_ACTION_MODAL_MODES } from 'common/consts';

const EntityLinkActionModal = ({ onRequestClose, mode, entityLink, entityType, ...props }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { error, success, inprogress } = useSelector(
        ({ EntityLinkReducer }) => EntityLinkReducer,
    );
    const titleKey =
        mode === ENTITY_LINK_ACTION_MODAL_MODES.REMOVE
            ? 'addBusiness.modal.entityLinkManagement.title.remove'
            : mode === ENTITY_LINK_ACTION_MODAL_MODES.RESEND
            ? 'addBusiness.modal.entityLinkManagement.title.resend'
            : '';
    const questionKey =
        mode === ENTITY_LINK_ACTION_MODAL_MODES.REMOVE
            ? 'addBusiness.modal.entityLinkManagement.question.removeConfirmation'
            : mode === ENTITY_LINK_ACTION_MODAL_MODES.RESEND
            ? 'addBusiness.modal.entityLinkManagement.question.resendConfirmation'
            : '';
    const successMessageKey =
        mode === ENTITY_LINK_ACTION_MODAL_MODES.REMOVE
            ? 'addBusiness.modal.entityLinkManagement.remove.successMessage'
            : mode === ENTITY_LINK_ACTION_MODAL_MODES.RESEND
            ? 'addBusiness.modal.entityLinkManagement.resend.successMessage'
            : '';

    const okButton = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.entityLinkManagement.button.ok.label',
            }),
            btnType: 'default',
            onClick: () => {
                onRequestClose();
                resetState()
            },
        },
    ];

    const cancelYesButtons = [
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.entityLinkManagement.button.cancel.label',
            }),
            loadingMessage: intl.formatMessage({
                id: 'addBusiness.modal.entityLinkManagement.button.cancel.label',
            }),
            btnType: 'link',
            skin: 'muted',
            loading: inprogress,
            onClick: () => onRequestClose(),
        },
        {
            label: intl.formatMessage({
                id: 'addBusiness.modal.entityLinkManagement.button.confirm.label',
            }),
            loadingMessage: intl.formatMessage({
                id: 'addBusiness.modal.entityLinkManagement.button.confirm.label',
            }),
            onClick: () => {
                onConfirm();
            },
            loading: inprogress,
            btnType: 'default',
        },
    ];

    const resetState = () => {
        if (mode === ENTITY_LINK_ACTION_MODAL_MODES.REMOVE) {
            dispatch({
                type: DELETE_ENTITY_LINK_RESET,
            });
        }

        if (mode === ENTITY_LINK_ACTION_MODAL_MODES.RESEND) {
            dispatch({
                type: RESEND_ENTITY_LINK_REQUEST_RESET,
            });
        }
    }

    const onConfirm = () => {
        if (mode === ENTITY_LINK_ACTION_MODAL_MODES.REMOVE) {
            dispatch({
                type: DELETE_ENTITY_LINK_CALL,
                payload: {
                    id: entityLink._id,
                    entityType,
                },
            });
        }

        if (mode === ENTITY_LINK_ACTION_MODAL_MODES.RESEND) {
            dispatch({
                type: RESEND_ENTITY_LINK_REQUEST_CALL,
                payload: {
                    id: entityLink._id,
                    entityType,
                    newApprovalStatus: ENTITY_LINK_STATUS_PENDING
                },
            });
        }
    };

    useEffect(() => {
        resetState()
    }, []);

    return (
        <Modal
            title={titleKey ? intl.formatMessage({ id: titleKey }) : ''}
            size="sm"
            handleOnDismiss={onRequestClose}
            footerActions={error || success ? okButton : cancelYesButtons}
            showCloseButton={false}
            {...props}
        >
            <ContentRenderer
                source={
                    questionKey
                        ? intl.formatMessage(
                              { id: questionKey },
                              { entityName: entityLink.requestedEntity.name || '' },
                          )
                        : ''
                }
            />
            {success && (
                <Small color="success">
                    {intl.formatMessage({
                        id: successMessageKey,
                    })}
                </Small>
            )}
            {error && (
                <Small color="danger">
                    {intl.formatMessage({
                        id: error,
                    })}
                </Small>
            )}
        </Modal>
    );
};

EntityLinkActionModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    action: PropTypes.oneOf([ENTITY_LINK_ACTION_MODAL_MODES.REMOVE, ENTITY_LINK_ACTION_MODAL_MODES.RESEND]),
};

EntityLinkActionModal.defaultProps = {};

export default EntityLinkActionModal;
