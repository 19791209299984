import React from 'react';
import PropTypes from 'prop-types';
import { BACKGROUND_MODES } from 'common/consts';
import {
    BackgroundHeart,
    BackgroundHeartHalfSideSpaceBetween,
    BackgroundHeartSideSpaceBetween,
    BackgroundHeartSideSpaceBetweenFade,
    BackgroundHeartSideSpaceBetweenMobileRight,
    BackgroundTriangleBottomCenter,
    BackgroundTriangleFadeSpaceBetween,
    BackgroundTriangleSidesMiddleGradientBottom,
    BackgroundTriangleSpaceBetween,
} from './src/components';

const Background = ({ children, mode }) => {
  return (<>
    {
      mode === BACKGROUND_MODES.HEART ? <BackgroundHeart>{children}</BackgroundHeart> :
      mode === BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN ? <BackgroundHeartSideSpaceBetween>{children}</BackgroundHeartSideSpaceBetween> :
      mode === BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_MOBILE_RIGHT ? <BackgroundHeartSideSpaceBetweenMobileRight>{children}</BackgroundHeartSideSpaceBetweenMobileRight> :
      mode === BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_FADE ? <BackgroundHeartSideSpaceBetweenFade>{children}</BackgroundHeartSideSpaceBetweenFade> :
      mode === BACKGROUND_MODES.HEART_HALF_SIDES_SPACE_BETWEEN ? <BackgroundHeartHalfSideSpaceBetween>{children}</BackgroundHeartHalfSideSpaceBetween> : 
      mode === BACKGROUND_MODES.TRIANGLE_BOTTOM_SPACE_BETWEEN ? <BackgroundTriangleSpaceBetween>{children}</BackgroundTriangleSpaceBetween> : 
      mode === BACKGROUND_MODES.TRIANGLE_FADE_SPACE_BETWEEN ? <BackgroundTriangleFadeSpaceBetween>{children}</BackgroundTriangleFadeSpaceBetween> : 
      mode === BACKGROUND_MODES.TRIANGLE_SIDES_MIDDLE_GRADIENT_BOTTOM ? <BackgroundTriangleSidesMiddleGradientBottom>{children}</BackgroundTriangleSidesMiddleGradientBottom> : 
      mode === BACKGROUND_MODES.TRIANGLE_BOTTOM_CENTER ? <BackgroundTriangleBottomCenter>{children}</BackgroundTriangleBottomCenter> : 
      children
    }
  </>)
};

Background.propTypes = {
  mode: PropTypes.oneOf([
    BACKGROUND_MODES.HEART,
    BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN,
    BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_MOBILE_RIGHT,
    BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_FADE,
    BACKGROUND_MODES.HEART_HALF_SIDES_SPACE_BETWEEN,
    BACKGROUND_MODES.TRIANGLE_BOTTOM_SPACE_BETWEEN,
    BACKGROUND_MODES.TRIANGLE_FADE_SPACE_BETWEEN,
    BACKGROUND_MODES.TRIANGLE_SIDES_MIDDLE_GRADIENT_BOTTOM,
    BACKGROUND_MODES.TRIANGLE_BOTTOM_CENTER
  ])
};

export default Background;

