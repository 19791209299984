import styled from 'styled-components';
import { media } from 'baseline-ui/layout';
import BackgroundBase from './BackgroundBase'
import triangleFadeLeft from '../images/triangle-fade-left.svg'
import triangleFadeRight from '../images/triangle-fade-right.svg'

const BackgroundTriangleFadeSpaceBetween = styled(BackgroundBase)`
      background: none;

      ${media.md`
        background: url(${triangleFadeLeft}) left top, url(${triangleFadeRight}) right top;
        background-repeat: no-repeat;
      `}
    }
`;

export default BackgroundTriangleFadeSpaceBetween