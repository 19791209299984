import React, { useContext, useEffect } from 'react';
import AdminDefaultTemplate from 'components/pageLayout/templates/AdminDefaultTemplate';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'baseline-ui/button';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import CreateFavePageMainContainer from 'components/createFavePage/CreateFavePageMainContainer';
import CreateFavePageOptionContainer from 'components/createFavePage/CreateFavePageOptionContainer';
import { GaIcon } from 'baseline-ui/icon';
import CreateFavePageOptionLabel from 'components/createFavePage/CreateFavePageOptionLabel';
import { ADD_FAVEPAGE_PATH } from 'common/pathnames';
import PageHeader from 'components/pageLayout/PageHeader';
import { Col, Row } from 'baseline-ui/layout';
import CreateFavePageContainer from 'components/createFavePage/CreateFavePageContainer';
import CreateFavePageComingSoon from 'components/createFavePage/CreateFavePageComingSoon';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_BUSINESS_FORM_START } from 'redux/actions/BusinessFormActions';
import CreateFavePageOptionButtonContent from 'components/createFavePage/CreateFavePageOptionButtonContent';
import {
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_DESTINATION,
    ENTITY_TYPE_ICONS,
    ENTITY_TYPE_PEOPLE,
    ENTITY_TYPE_RECIPES,
    ENTITY_TYPE_TRIPS,
} from 'common/consts';
import CommonWrapper from './CommonWrapper';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

const CreateFavePage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);
    const { ownedBusinesses } = useSelector(({ BusinessesReducer }) => BusinessesReducer);
    const isAdmin = useSelector(isAdminSelector);

    useEffect(() => {
        dispatch({ type: RESET_BUSINESS_FORM_START });
    }, [dispatch]);

    return (
        <CommonWrapper>
            <AdminDefaultTemplate
                bgEnd={theme.site.admin.bgEnd}
                bgStart={theme.site.admin.bgStart}
                banner={
                    <PageHeader title={intl.formatMessage({ id: 'createFavePage.page.title' })} />
                }
                main={
                    <CreateFavePageContainer>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                <Row justifyContent="center">
                                    <Col xxs={30} lg={20}>
                                        <CreateFavePageMainContainer>
                                            {isAdmin && (
                                                <CreateFavePageOptionContainer>
                                                    <Button
                                                        block
                                                        btnType="outlined"
                                                        onClick={() => {
                                                            navigate(
                                                                `${ADD_FAVEPAGE_PATH}/${ENTITY_TYPE_DESTINATION}`,
                                                            );
                                                        }}
                                                    >
                                                        <CreateFavePageOptionButtonContent>
                                                            <GaIcon
                                                                icon={
                                                                    ENTITY_TYPE_ICONS[
                                                                        ENTITY_TYPE_DESTINATION
                                                                    ]
                                                                }
                                                                size={80}
                                                            />
                                                            <CreateFavePageOptionLabel>
                                                                <FormattedMessage id="createFavePage.page.forYourDestination.label" />
                                                            </CreateFavePageOptionLabel>
                                                        </CreateFavePageOptionButtonContent>
                                                    </Button>
                                                </CreateFavePageOptionContainer>
                                            )}
                                            <CreateFavePageOptionContainer>
                                                <Button
                                                    block
                                                    btnType="outlined"
                                                    onClick={() => {
                                                        navigate(
                                                            `${ADD_FAVEPAGE_PATH}/${ENTITY_TYPE_BUSSINESS}`,
                                                        );
                                                    }}
                                                >
                                                    <CreateFavePageOptionButtonContent>
                                                        <GaIcon
                                                            icon={
                                                                ENTITY_TYPE_ICONS[
                                                                    ENTITY_TYPE_BUSSINESS
                                                                ]
                                                            }
                                                            size={80}
                                                        />
                                                        <CreateFavePageOptionLabel>
                                                            <FormattedMessage id="createFavePage.page.forYourBusiness.label" />
                                                        </CreateFavePageOptionLabel>
                                                    </CreateFavePageOptionButtonContent>
                                                </Button>
                                            </CreateFavePageOptionContainer>
                                            <CreateFavePageOptionContainer>
                                                <Button
                                                    block
                                                    btnType="outlined"
                                                    onClick={() => {
                                                        navigate(
                                                            `${ADD_FAVEPAGE_PATH}/${ENTITY_TYPE_PEOPLE}`,
                                                        );
                                                    }}
                                                >
                                                    <CreateFavePageOptionButtonContent>
                                                        <GaIcon
                                                            icon={
                                                                ENTITY_TYPE_ICONS[
                                                                    ENTITY_TYPE_PEOPLE
                                                                ]
                                                            }
                                                            size={80}
                                                        />
                                                        <CreateFavePageOptionLabel>
                                                            <FormattedMessage id="createFavePage.page.forYourProfession.label" />
                                                        </CreateFavePageOptionLabel>
                                                    </CreateFavePageOptionButtonContent>
                                                </Button>
                                            </CreateFavePageOptionContainer>
                                        </CreateFavePageMainContainer>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CreateFavePageContainer>
                }
            />
        </CommonWrapper>
    );
};

export default CreateFavePage;
