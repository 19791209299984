import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PATH } from 'common/pathnames';
import { FormSignUp } from 'baseline-ui/auth';
import { FormattedMessage, useIntl } from 'react-intl';
import useProcessErrorMessage from 'utils/useProcessErrorMessage';
import CommonWrapper from './CommonWrapper';
import { POST_SIGNUP_PROCESS, POST_SIGNUP_START } from '../redux/actions/AuthenticationActions';
import { useLoading } from '../common/useLoading';
import WelcomeMainContainer from './Welcome/WelcomeMainContainer';
import FlexWrapper from 'components/FlexWrapper';
import Background from 'baseline-ui/background';
import { BACKGROUND_MODES } from 'common/consts';
import { ContentRenderer } from 'baseline-ui/helpers';
import ExtraLargeH1 from 'baseline-ui/typography/src/components/ExtraLargeH1';

const SignUpScreen = () => {
    const intl = useIntl();
    const isLoading = useLoading(POST_SIGNUP_PROCESS);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = useProcessErrorMessage({ processName: POST_SIGNUP_PROCESS });
    const handleOnSubmitSignUp = (values) => {
        dispatch({
            type: POST_SIGNUP_START,
            payload: {
                ...values,
                navigate,
            },
        });
    };

    return (
        <CommonWrapper>
            <Background mode={BACKGROUND_MODES.HEART_SIDES_SPACE_BETWEEN_FADE}>
                <WelcomeMainContainer justifyContent="flex-start">
                    <ExtraLargeH1
                        colorValue="secondary"
                        fontSize={48}
                        textAlign="center"
                        fontWeight={800}
                        margin={{
                            top: 50,
                            bottom: 20,
                        }}
                    >
                        <FormattedMessage id="signup.title" />
                    </ExtraLargeH1>
                    <FlexWrapper
                        shadow
                        border
                        width={45}
                        padding={{
                            top: 40,
                            right: 40,
                            bottom: 15,
                            left: 40,
                        }}
                    >
                        <FormSignUp
                            showHeading={false}
                            siteName={intl.formatMessage({ id: 'siteName' })}
                            onSubmitSignUp={(values) => handleOnSubmitSignUp(values)}
                            errorMessage={errorMessage}
                            isLoading={isLoading}
                            inProp
                            toSignIn={LOGIN_PATH}
                            appear={false}
                            footnote={
                                <ContentRenderer
                                    textAlign="center"
                                    color="muted"
                                    source={intl.formatMessage(
                                        {
                                            id: 'signup.footnote',
                                        },
                                        {
                                            loginUrl: LOGIN_PATH,
                                        },
                                    )}
                                />
                            }
                        />
                    </FlexWrapper>
                </WelcomeMainContainer>
            </Background>
        </CommonWrapper>
    );
};

export default SignUpScreen;
