import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { H3, P } from 'baseline-ui/typography';
import { FieldContainer, FormTextarea } from 'baseline-ui/form';
import { EntityPicker } from 'components/EntityPicker';
import { businessFormSelector } from 'redux/selectors/businessFormSelectors';
import { Field, Form, useFormikContext } from 'formik';
import { ButtonArea } from 'components/ButtonArea';
import { Button } from 'baseline-ui/button';
import { SEND_ENTITY_LINK_REQUEST_CALL, SEND_ENTITY_LINK_REQUEST_RESET } from 'redux/actions/EntityLinkActions';

export const initialValues = {
    // From this form.
    message: '',
    // From the EntityPicker component.
    entitypicker_search: '',
    entitypicker_selected: '',
};

export const AddBusinessRequestToLinkForm = ({entityType} = props) => {
    const dispatch = useDispatch();
    const [statusMessage, setStatusMessage] = useState('');
    const intl = useIntl();
    const [entity, setEntity] = useState(null);
    const { values, resetForm } = useFormikContext();
    const { _id: requestingEntityId, name, position, ownerFirstName, ownerLastName, firstName, lastName } = useSelector(businessFormSelector);
    const { sendEntityLinkRequestState: {error, success} } = useSelector(({ EntityLinkReducer }) => EntityLinkReducer);

    useEffect(() => {        
        if (error) {
            setStatusMessage(
                intl.formatMessage({
                    id: `addBusiness.page.sections.requestToLink.${entityType}.error.${error ?? 'default'}`,
                }),
            );
        }
        
        if (success) {
            resetForm();
            setStatusMessage(
                intl.formatMessage({
                    id: 'addBusiness.page.sections.requestToLink.success',
                }),
            );
        }
    }, [error, success]);

    const defaultMessage = entity
        ? intl.formatMessage(
              { id: 'addBusiness.page.sections.requestToLink.defaultMessage' },
              {
                  name: `${firstName || ownerFirstName} ${lastName || ownerLastName}`,
                  position,
                  businessName: name,
                  requestedBusinessName: entity.name,
              },
          )
        : intl.formatMessage({ id: 'addBusiness.page.sections.requestToLink.message.placeholder' });

    const onSubmit = (evt) => {
        evt.preventDefault();
        if (!entity) {
            return;
        }

        const payload = {
            name,
            firstName: firstName || ownerFirstName,
            lastName: lastName || ownerLastName,
            position,
            requestingEntityId,
            entityRequestedType: entityType,
            entityId: entity._id,
            message: values.message || defaultMessage,
        };
        dispatch({ type: SEND_ENTITY_LINK_REQUEST_CALL, payload });
    };
    
    const onAcknowledge = () => {
        dispatch({ type: SEND_ENTITY_LINK_REQUEST_RESET });
        setStatusMessage('');
    };

    return (
        <Form noValidate>
            {statusMessage && <H3>{statusMessage}</H3>}
            <FieldContainer hidden={!!statusMessage}>
                <P>
                    <FormattedMessage id={`addBusiness.page.sections.requestToLink.findbusiness.${ entityType }.subtitle`} />
                </P>
                <EntityPicker
                    identifier="requestToLinkBusinessPicker"
                    label={`addBusiness.page.sections.requestToLink.findbusiness.${ entityType }.placeholder`}
                    type={entityType}
                    onChange={(ent) => setEntity(ent)}
                />
            </FieldContainer>
            <FieldContainer hidden={!!statusMessage}>
                <H3 color="primary">
                    <FormattedMessage id="addBusiness.page.sections.requestToLink.message.title" />
                </H3>
                <Field
                    id="message"
                    name="message"
                    required
                    rows={16}
                    component={FormTextarea}
                    label={defaultMessage}
                />
            </FieldContainer>
            <ButtonArea>
                {statusMessage ? (
                    <Button type="button" skin="primary" onClick={onAcknowledge}>
                        <FormattedMessage id="addBusiness.page.sections.requestToLink.findbusiness.acknowledge" />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        skin="primary"
                        disabled={!entity}
                        onClick={onSubmit}
                    >
                        <FormattedMessage id="addBusiness.page.sections.requestToLink.findbusiness.submit" />
                    </Button>
                )}
            </ButtonArea>
        </Form>
    );
};

export default AddBusinessRequestToLinkForm;
