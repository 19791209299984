import { rem } from 'polished';
import styled, { css } from 'styled-components';
import siteBaseTheme from 'styles/siteBaseTheme';
import { media } from 'baseline-ui/layout';
import { typographyUtils } from 'baseline-ui/typography';
import { has } from 'lodash'

const FlexWrapper = styled.div`
    background: ${({background}) => {
        if(!background) {
           return siteBaseTheme.base.colors.light
        }

        return background
    }};
    align-items: stretch;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: ${(props) => has(props, 'xsGap') ? rem(props.xsGap) : rem(16)};
    border-radius: ${(props) => has(props, 'borderRadius') ? typographyUtils.processSize(props.borderRadius) : rem(16)};
    margin-top: ${({margin}) => {
        if(has(margin, 'top')) {
            return typographyUtils.processSize(margin.top)
        }

        return rem(16)
    }};
    margin-right: ${({margin}) => {
        if(has(margin, 'right')) {
            return typographyUtils.processSize(margin.right)
        }

        return 'auto'
    }};
    margin-bottom: ${({margin}) => {
        if(has(margin, 'bottom')) {
            return typographyUtils.processSize(margin.bottom)
        }

        return rem(16)
    }};
    margin-left: ${({margin}) => {
        if(has(margin, 'left')) {
            return typographyUtils.processSize(margin.left)
        }

        return 'auto'
    }};
    padding-top: ${({padding}) => {
        if(has(padding, 'top')) {
            return typographyUtils.processSize(padding.top)
        }

        return rem(16)
    }};
    padding-right: ${({padding}) => {
        if(has(padding, 'right')) {
            return typographyUtils.processSize(padding.right)
        }

        return rem(16)
    }};
    padding-bottom: ${({padding}) => {
        if(has(padding, 'bottom')) {
            return typographyUtils.processSize(padding.bottom)
        }

        return rem(16)
    }};
    padding-left: ${({padding}) => {
        if(has(padding, 'left')) {
            return typographyUtils.processSize(padding.left)
        }

        return rem(16)
    }};
    ${({ border }) => {
        return (
            border &&
            css`
                border: ${rem(1)} solid ${siteBaseTheme.base.grays.grayLightest};
            `
        );
    }}
    ${({ shadow }) => {
        return (
            shadow &&
            css`
                box-shadow: ${rem(0)} ${rem(4)} ${rem(4)} 0 rgba(0, 0, 0, 0.1);
            `
        );
    }}
    ${({ width }) => {
        return width
            ? css`
                  width: 100%;
                  ${media.md`
                        width: ${width}%;
                    `};
              `
            : css`
                  width: 100%;
              `;
    }}
    ${({ maxWidth }) => {
        if(maxWidth) {
            return css`
                    ${media.md`
                        max-width: ${typographyUtils.processSize(maxWidth)};
                    `}
                `
        }
    }}
    ${media.md`
        gap: ${(props) => has(props, 'mdGap') ? rem(props.mdGap) : rem(32)};
    `};
`;

export default FlexWrapper;
