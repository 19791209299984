import styled from 'styled-components';
import siteBaseTheme from 'styles/siteBaseTheme';
import {typographyTheme} from 'baseline-ui/typography';
import {rem} from 'polished';
import {media} from 'baseline-ui/layout';

export const DropdownMenu = styled.div`
    position: relative;
    background-color: ${({ skin }) => (skin && `${siteBaseTheme.base.colors[skin]}`)}; 
    box-shadow: none;
    padding: ${rem(8)} ${rem(0)};
    border-radius: ${rem(8)};
  
    ${media.md`
        position: absolute;
        min-width: ${rem(160)};
        top: ${rem(71)}; 
        right: 0;
        z-index: 1000; 
        text-align: center;
        box-shadow: ${rem(0)} ${rem(2)} ${rem(8)} rgba(0, 0, 0, 0.15);
    `}
   
`;
export const DropdownMenuItem = styled.div`
    &:hover,
    &:focus {
        color: ${typographyTheme.typography.colors.primary};
    }
    cursor: pointer;
    text-decoration: none;
    display: block;
    
    ${media.sm`
        &:hover,
        &:focus {
            color: ${typographyTheme.typography.colors.primary}; 
        }
    `}
`;


