import { call, delay, getContext, put, select, takeEvery } from 'redux-saga/effects';
import ModalSaveBusinessConfirmed from 'screens/BusinessForm/ModalSaveBusinessConfirmed';
import { EDIT_FAVEPAGE_PATH } from 'common/pathnames';
import {
    GET_BUSINESS_FORM_PROCESS,
    GET_BUSINESS_FORM_START,
    GET_BUSINESS_FORM_SUCCESS,
    GET_DATA_FROM_LOCAL_STORAGE_PROCESS,
    GET_DATA_FROM_LOCAL_STORAGE_START,
    POST_BUSINESS_FORM_PROCESS,
    POST_BUSINESS_FORM_START,
    RESET_BUSINESS_FORM_START,
    SAVE_DATA_TO_LOCAL_STORAGE_PROCESS,
    SAVE_DATA_TO_LOCAL_STORAGE_START,
    SET_BUSINESS_FORM_DATA_START,
} from '../actions/BusinessFormActions';
import genericSagaHandler from './CommonSaga';
import { EditOwnBusiness, GetOwnedBusiness, OwnBusiness } from 'api';
import {
    BUSINESS_APPROVAL_STATUS_APPROVED,
    BUSINESS_APPROVAL_STATUS_DRAFT,
    BUSINESS_APPROVAL_STATUS_PENDING,
    BUSINESS_FORM_KEY,
    ENTITY_TYPE_BUSSINESS,
    ENTITY_TYPE_DESTINATION,
    ENTITY_TYPE_PEOPLE,
} from 'common/consts';
import { getBusinessesSaga } from './BussinessesSagas';
import { saveProductsBulkSaga } from './EditProductSagas';
import { isDraftlikeState } from 'utils/businessUtils';
import { max } from 'lodash';

function* assemblePayloadByType(type) {
    const {
        name,
        ownerFirstName,
        ownerLastName,
        website,
        description,
        position,
        phone,
        privatephone,
        email,
        formattedAddress,
        selectedCategories = [],
        links,
        photos,
        id,
        _id,
        approval_status,
        showAddress,
        adminOnly,
        // ownerFirstName
        // ownerLastName
        // phone
        // photos
        // position
        // privatephone
    } = yield select(({ BusinessFormReducer }) => BusinessFormReducer);
    let payloadToSend = {};
    if (type === ENTITY_TYPE_PEOPLE) {
        payloadToSend = {
            firstName: ownerFirstName,
            lastName: ownerLastName,
            privatephone: privatephone,
            phone: phone,
            position: position,
            id,
            entity_id: _id,
            website, // not included in backend validation
            description,
            showAddress,
            tags: selectedCategories.map((tag) => tag.id),
            photos: JSON.stringify(
                photos
                    ? photos
                          .filter((photo) => photo.state === 'original')
                          .map((photo, index) => {
                              const { state, ...rest } = photo;

                              return { ...rest, order: index };
                          })
                    : [],
            ),
            photosOrder: JSON.stringify(
                photos
                    ? photos.map((photo, index) => {
                          return { name: photo.name, order: index };
                      })
                    : [],
            ),
            links: JSON.stringify(links),
            name: ownerFirstName + ' ' + ownerLastName,
            phone, // not included in backend validation
            privatephone, // not included in backend validation
            email,
            formattedAddress,
            position, // not included in backend validation
            /** Admin-only property */
            advertise: adminOnly?.advertise,
            advertisementPriority: adminOnly?.advertisementPriority,
            followupStatus: adminOnly?.followupStatus,
            privateCell: adminOnly?.privateCell,
        };
    } else if (type === ENTITY_TYPE_BUSSINESS || type === ENTITY_TYPE_DESTINATION) {
        payloadToSend = {
            id,
            business_id: _id,
            entity_id: _id,
            website,
            description,
            tags: selectedCategories.map((tag) => tag.id),
            photos: JSON.stringify(
                photos
                    ? photos
                          .filter((photo) => photo.state === 'original')
                          .map((photo, index) => {
                              const { state, ...rest } = photo;

                              return { ...rest, order: index };
                          })
                    : [],
            ),
            photosOrder: JSON.stringify(
                photos
                    ? photos.map((photo, index) => {
                          return { name: photo.name, order: index };
                      })
                    : [],
            ),
            links: JSON.stringify(links),
            name,
            showAddress,
            ownerFirstName,
            ownerLastName,
            phone,
            privatephone,
            email,
            formattedAddress,
            position,
            /** Admin-only property */
            advertiseAppAnchor: adminOnly?.advertise.appAnchor,
            advertiseAppAnchorCityList: adminOnly.advertise.appAnchorCityList,
            advertiseAppAnchorOrder: adminOnly.advertise.appAnchorOrder,
            advertiseAppAnchorOrderEnabled: adminOnly.advertise.appAnchorOrderEnabled,
            advertiseAppAnchorRotate: adminOnly.advertise.appAnchorRotate,
            advertiseAppAnchorSearchCity: adminOnly.advertise.appAnchorSearchCity,
            advertiseCategoriesAndFilters: adminOnly.advertise.categoriesAndFilters,
            advertisementPriority: adminOnly?.advertisementPriority,
            followupStatus: adminOnly?.followupStatus,
            privateCell: adminOnly?.privateCell,
            maxNotificationsPerWeek: isNaN(adminOnly?.maxNotificationsPerWeek)
                ? 0
                : parseInt(adminOnly?.maxNotificationsPerWeek),
        };
    }
    return payloadToSend;
}

function* postBusinessFormSaga({ payload }) {
    yield genericSagaHandler(POST_BUSINESS_FORM_PROCESS, function* () {
        const { navigate } = yield getContext('router');

        const { photos, id, _id, approval_status, type } = yield select(
            ({ BusinessFormReducer }) => {
                console.log(BusinessFormReducer);
                return BusinessFormReducer;
            },
        );

        // by default we send as draft
        let newApprovalStatus = payload.approval_status ?? BUSINESS_APPROVAL_STATUS_DRAFT;
        if (payload.submitForApproval) {
            // we send as pending on user request
            newApprovalStatus = BUSINESS_APPROVAL_STATUS_PENDING;
        } else if (isDraftlikeState(approval_status)) {
            // If it is a DRAFT-like, but not draft.
            newApprovalStatus = approval_status;
        }
        // those which already have a status of approved or pending keep pushing the same status
        else if (
            approval_status === BUSINESS_APPROVAL_STATUS_APPROVED ||
            approval_status === BUSINESS_APPROVAL_STATUS_PENDING
        ) {
            newApprovalStatus = approval_status;
        }
        const payloadToSend = yield assemblePayloadByType(type);
        payloadToSend.approval_status = newApprovalStatus;

        const formData = new FormData();

        formData.append('type', type);

        // eslint-disable-next-line no-restricted-syntax
        for (const item in payloadToSend) {
            if (payloadToSend[item] !== undefined) {
                if (Array.isArray(payloadToSend[item])) {
                    formData.append(item, JSON.stringify(payloadToSend[item]));
                } else {
                    formData.append(item, payloadToSend[item]);
                }
            }
        }

        photos?.forEach((photo) => {
            if (photo.state === 'new' || photo.state === 'updated') {
                formData.append(photo.name, photo);
            }
        });

        const response = _id ? yield EditOwnBusiness(formData) : yield OwnBusiness(formData);

        const business = response?.payload;

        if (business) {
            yield put({
                type: SET_BUSINESS_FORM_DATA_START,
                payload: {
                    ...business,
                    photos: business.photos?.map((item) => {
                        return {
                            ...item,
                            state: 'original',
                        };
                    }),
                },
            });

            yield call(saveProductsBulkSaga);
        }

        /** hasUnsavedChanges
         * Set to false, all unsaved changes have been saved
         */
        yield put({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: { hasUnsavedChanges: false },
        });

        localStorage.removeItem(BUSINESS_FORM_KEY);
        // We want this to happen only when we are submitting a business that doesn't exist yet
        if (!id) {
            // We have to add a delay of 200ms delay because the API doesn't return anything if we call immediately after the post
            yield delay(500);
            yield call(getBusinessesSaga);
        }

        /**
         * Navigate to Edit Business screen
         * replace: Set replace to true and avoid users to navigate when adding and editing a business
         * processCompleteWithFeedback: Use location state to avoid process complete to show feedback
         * when navigating from Add Business to Edit Business as it now uses a modal for confirmation
         */

        // eslint-disable-next-line camelcase
        const navigateStateAddBusiness = !approval_status
            ? {
                  state: { processCompleteWithFeedback: false },
              }
            : {};

        const renderEditBusinessPath = `${EDIT_FAVEPAGE_PATH}/${encodeURIComponent(
            response?.payload?.id ? response?.payload?.id : _id,
        )}`;

        navigate(renderEditBusinessPath, { replace: true, ...navigateStateAddBusiness });

        /** showModal: Save Business confirmation modal   */
        if (payload.showModal) {
            payload.showModal(ModalSaveBusinessConfirmed);
        }
    });
}

function* saveDataToLocalStorageSaga() {
    yield genericSagaHandler(SAVE_DATA_TO_LOCAL_STORAGE_PROCESS, function* () {
        const businessFormReducer = yield select(({ BusinessFormReducer }) => BusinessFormReducer);
        localStorage.setItem(BUSINESS_FORM_KEY, JSON.stringify(businessFormReducer));
    });
}

function* getDataFromLocalStorageSaga() {
    yield genericSagaHandler(GET_DATA_FROM_LOCAL_STORAGE_PROCESS, function* () {
        yield put({
            type: RESET_BUSINESS_FORM_START,
        });
        const businessFormLocalStorage = localStorage.getItem(BUSINESS_FORM_KEY);
        if (businessFormLocalStorage) {
            const storedBusinessFrormReducer = JSON.parse(businessFormLocalStorage);
            yield put({
                type: SET_BUSINESS_FORM_DATA_START,
                payload: storedBusinessFrormReducer,
            });
        }
    });
}

function* getBusinessFormSaga({ payload }) {
    yield genericSagaHandler(GET_BUSINESS_FORM_PROCESS, function* getBusinessForm() {
        yield put({
            type: RESET_BUSINESS_FORM_START,
        });
        const businessData = yield GetOwnedBusiness(payload);

        yield put({
            type: GET_BUSINESS_FORM_SUCCESS,
            payload: businessData.business,
        });
        yield put({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: { selectedCategories: businessData.mapping.tags },
        });
        yield put({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                photos: businessData.business.photos?.map((item) => {
                    return {
                        ...item,
                        state: 'original',
                    };
                }),
            },
        });
    });
}

export default function* BusinessFormSagas() {
    yield takeEvery(SAVE_DATA_TO_LOCAL_STORAGE_START, saveDataToLocalStorageSaga);
    yield takeEvery(GET_DATA_FROM_LOCAL_STORAGE_START, getDataFromLocalStorageSaga);

    yield takeEvery(POST_BUSINESS_FORM_START, postBusinessFormSaga);
    yield takeEvery(GET_BUSINESS_FORM_START, getBusinessFormSaga);
}
