import styled from 'styled-components';
import {rem} from 'polished';

const MenuIcon = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  }
`;

export default MenuIcon;