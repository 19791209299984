import React, { useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button, AnchorButton } from 'baseline-ui/button';
import { ModalContext } from 'baseline-ui/modal';
import { ADD_BUSINESS_LINKS } from 'common/addBusinessViewIds';
import FavePagePreviewLinksContainer from './FavePagePreviewLinksContainer';
import { GaIcon } from 'baseline-ui/icon';

const truncateText = (text, maxLength = 30) => {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
};

const Popup = ({ isOpen, content, onClose }) => {
    if (!isOpen) return null;
    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            zIndex: 1000,
           
        }}>
            <div style={{ 
             display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
             }}>
            <p>{content}</p>
            <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    );
};

const FavePagePreviewLinks = ({ address, phone, geolocation, links, addToSection }) => {
    const { hideModal } = useContext(ModalContext);
    const [showMenu, setShowMenu] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    console.log(links, "linkslinkslinks")

    const openPopup = (content) => {
        setPopupContent(content);
        setPopupOpen(true);
        setShowMenu(false);
    };

    const renderContent = useMemo(() => {
        if (isEmpty(links) && !phone && !address) {
            return (
                <Button
                    btnType="link"
                    size="sm"
                    icon="plus"
                    onClick={() => {
                        hideModal();
                        addToSection(ADD_BUSINESS_LINKS);
                    }}
                >
                    Add Links
                </Button>
            );
        }

        const phoneLink = phone ? {
            content: phone,
            icon: <GaIcon icon="phone" color="rgb(241, 90, 36)" size={20} />,
        } : null;

        const siteLink = links.find(link => link.caption === 'Website Address') ? {
            content: links.find(link => link.caption === 'Website Address').caption,
            icon: <GaIcon icon="globe" color="rgb(241, 90, 36)" size={20} />,
        } : null;

        const addressLink = address ? {
            content: address,
            icon: <GaIcon icon="map-pin" color="rgb(241, 90, 36)" size={20} />,
        } : null;

        const primaryLinks = [phoneLink, siteLink, addressLink].filter(link => link !== null);
        const remainingLinks = links.filter(
            link => 
              link.caption !== "Website Address" && 
              !primaryLinks.some(primary => primary.content === link.url)
          );        

        return (
            <>
                {primaryLinks.map((link, index) => (
                    <div style={{ paddingBottom: 4, paddingTop: 4 }} key={`primary-link-${index}`}>
                        <AnchorButton 
                            block 
                            style={{
                                borderRadius: "7px",
                                color: 'rgb(241, 90, 36)',
                                whiteSpace: 'normal',
                                background: 'transparent',
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 4,
                                paddingBottom: 4,
                            }} 
                            onClick={(e) => {
                                e.preventDefault();
                                openPopup(link.content);
                            }}
                        >
                            {link.icon}
                        </AnchorButton>
                    </div>
                ))}

                {remainingLinks.length > 0 && (
                    <div key="more-links" style={{ position: 'relative' }}>
                        <AnchorButton
                           onClick={() => {
                            setShowMenu(prev => !prev);
                            setPopupOpen(false);
                        }}
                            style={{ 
                                whiteSpace: 'normal', 
                                color: 'rgb(241, 90, 36)', 
                                background: 'transparent', 
                                borderRadius: "7px", 
                                paddingLeft: 30, 
                                paddingRight: 30, 
                                paddingTop: 5, 
                                paddingBottom: 5
                            }}
                        >
                            ...
                        </AnchorButton>
                        {showMenu && (
                            <div style={{
                                position: 'absolute', 
                                zIndex: 1000, 
                                background: '#fff', 
                                border: '1px solid #ccc', 
                                borderRadius: 10,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                width: 210,
                                left: '-150px',
                                top: '30px',
                            }}>
                                {remainingLinks.map((link, index) => (
                                    <div key={link.url}>
                                        <a 
                                            href={link.url} 
                                            target="_blank" 
                                            rel="noreferrer" 
                                            style={{
                                                color: 'black',
                                                textDecoration: 'none',
                                                fontSize: 14,
                                                display: 'block',
                                                padding: '8px 4px',
                                            }}
                                        >
                                            {truncateText(link.caption, 30)}
                                        </a>
                                        {index < remainingLinks.length - 1 && (
                                            <div style={{
                                                height: 1,
                                                backgroundColor: '#ccc',
                                            }} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    }, [links, phone, address, hideModal, addToSection, showMenu]);

    return (
        <FavePagePreviewLinksContainer>
            {renderContent}
            <Popup isOpen={isPopupOpen} content={popupContent} onClose={() => setPopupOpen(false)} />
        </FavePagePreviewLinksContainer>
    );
};

FavePagePreviewLinks.propTypes = {
    address: PropTypes.string,
    phone: PropTypes.string,
    geolocation: PropTypes.shape({
        coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    links: PropTypes.arrayOf(
        PropTypes.shape({
            caption: PropTypes.string,
            url: PropTypes.string,
            type: PropTypes.string,
        }),
    ),
    addToSection: PropTypes.func,
};

FavePagePreviewLinks.defaultProps = {
    address: undefined,
    phone: undefined,
    geolocation: undefined,
    links: [],
    addToSection: () => {},
};

export default FavePagePreviewLinks;
