import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import iconPaths from 'data/selection.json';
import iconPropTypes from '../prop-types/iconPropTypes';

const getPath = (iconName) => {
    const icons = iconPaths.icons.find((icon) => icon.properties.name === iconName);
    if (icons && !icons.icon.isMulticolor) {
        return [icons.icon.paths.join(' ')];
    }
    if (icons && icons.icon.isMulticolor) {
        return icons.icon.paths;
    }
    console.warn(`icon ${iconName} does not exist.`); // eslint-disable-line no-console
    return [];
};
const getAttrs = (iconName) => {
    const icons = iconPaths.icons.find((icon) => icon.properties.name === iconName);
    if (icons && icons.icon.attrs) {
        return icons.icon?.attrs;
    }
    return [];
};
const getSizes = (iconName) => {
    let width;
    let height;
    const icons = iconPaths.icons.find((icon) => icon.properties.name === iconName);
    if (icons.icon.width) {
        width = icons.icon.width;
        height = 1024;
        return { width, height };
    }
    return { width: 1024, height: 1024 };
};

const GaIcon = ({ color, size, icon }) => {
    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        path: {
            fill: color,
        },
    };
    if (!icon) {
        return null;
    }
    const paths = getPath(icon);
    const attrs = getAttrs(icon);

    return (
        <svg
            style={styles.svg}
            width={rem(size)}
            height={rem(size)}
            viewBox={`0 0 ${getSizes(icon).width} ${getSizes(icon).height}`}
        >
            {paths.map((path, index) => (
                <path
                    key={index}
                    d={path}
                    fill={attrs && attrs[index]?.fill ? attrs[index].fill : color}
                />
            ))}
        </svg>
    );
};

GaIcon.propTypes = {
    icon: iconPropTypes.iconSet.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
};

GaIcon.defaultProps = {
    size: 22,
    color: 'currentColor',
};

export default GaIcon;
