import React from 'react';
import PropTypes from 'prop-types';
import { ProgressiveBg } from 'baseline-ui/helpers';
import { Button } from 'baseline-ui/button';
import PhotoListItemContainer from './PhotoListItemContainer';
import PhotoListItemFooter from './PhotoListItemFooter';
import PhotoListItemImageContainer from './PhotoListItemImageContainer';
import PhotoListItemOuter from './PhotoListItemOuter';
import { FormRadio } from 'baseline-ui/form';
import { Field } from 'formik';
import { Col, Row } from 'baseline-ui/layout';

const PhotoListItem = ({
    image,
    onClickEdit,
    onClickDelete,
    radioId,
    radioName,
    radioValue,
    radioLabel,
}) => {
    return (
        <PhotoListItemOuter>
            <PhotoListItemContainer>
                <PhotoListItemImageContainer>
                    <ProgressiveBg image={image} />
                </PhotoListItemImageContainer>
                {(onClickEdit || onClickDelete) && (
                    <PhotoListItemFooter>
                        {onClickEdit && (
                            <Button btnType="link" icon="edit" size="sm" onClick={onClickEdit} />
                        )}
                        {onClickDelete && (
                            <Button btnType="link" icon="x" size="sm" onClick={onClickDelete} />
                        )}
                    </PhotoListItemFooter>
                )}
                {radioValue && (
                    <Row alignItems={'center'}>
                        <Col>
                            <Field
                                component={FormRadio}
                                id={radioId}
                                name={radioName}
                                value={radioValue}
                            >
                                {radioLabel}
                            </Field>
                        </Col>
                    </Row>
                )}
            </PhotoListItemContainer>
        </PhotoListItemOuter>
    );
};

PhotoListItem.propTypes = {
    image: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    radioId: PropTypes.string,
    radioName: PropTypes.string,
    radioValue: PropTypes.string,
    radioLabel: PropTypes.string,
};

PhotoListItem.defaultProps = {};

export default PhotoListItem;
