import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { H1 } from 'baseline-ui/typography';
import { Formik } from 'formik';
import { AddBusinessRequestToLinkForm, initialValues } from './AddBusinessRequestToLinkForm';
import { SEND_ENTITY_LINK_REQUEST_RESET } from '../../redux/actions/EntityLinkActions';
import EntityLinksToEntity from 'components/EntityLink/EntityLinksToEntity';
import { ENTITY_LINK_TO_ENTITY_LIST_MODES } from 'common/consts';

const RequestToLink = ({entityType} = props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: SEND_ENTITY_LINK_REQUEST_RESET });
    }, []);    
    return (
        <>
            <H1 color="primary">
                <FormattedMessage id={`addBusiness.page.sections.requestToLink.${entityType}.title`} />
            </H1>
            <Formik initialValues={initialValues}>
                {() => <AddBusinessRequestToLinkForm entityType={entityType} />}
            </Formik>
            <EntityLinksToEntity entityType={entityType} mode={ENTITY_LINK_TO_ENTITY_LIST_MODES.SENT_REQUESTS} />
        </>
    );
};

export default RequestToLink;
