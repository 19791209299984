import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { media } from 'baseline-ui/layout';
import { has } from 'lodash';

const ListWrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (has(props, 'direction') ? props.direction : 'column')};
    justify-content: ${(props) => (has(props, 'justifyContent') ? props.justifyContent : 'center')};
    gap: ${(props) => (has(props, 'gapBetweenLists') ? rem(props.gapBetweenLists) : rem(40))};
    margin-top: ${({ margin }) => {
        if (has(margin, 'top')) {
            return typographyUtils.processSize(margin.top);
        }

        return 0;
    }};
    margin-right: ${({ margin }) => {
        if (has(margin, 'right')) {
            return typographyUtils.processSize(margin.right);
        }

        return 'auto';
    }};
    margin-bottom: ${({ margin }) => {
        if (has(margin, 'bottom')) {
            return typographyUtils.processSize(margin.bottom);
        }

        return 0;
    }};
    margin-left: ${({ margin }) => {
        if (has(margin, 'left')) {
            return typographyUtils.processSize(margin.left);
        }

        return 'auto';
    }};
    padding-top: ${({ padding }) => {
        if (has(padding, 'top')) {
            return typographyUtils.processSize(padding.top);
        }

        return 0;
    }};
    padding-right: ${({ padding }) => {
        if (has(padding, 'right')) {
            return typographyUtils.processSize(padding.right);
        }

        return 0;
    }};
    padding-bottom: ${({ padding }) => {
        if (has(padding, 'bottom')) {
            return typographyUtils.processSize(padding.bottom);
        }

        return 0;
    }};
    padding-left: ${({ padding }) => {
        if (has(padding, 'left')) {
            return typographyUtils.processSize(padding.left);
        }

        return 0;
    }};

    & ul {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        gap: ${(props) => (has(props, 'liGap') ? rem(props.liGap) : rem(40))};
        margin: 0;
    }

    & li {
        display: flex;
        position: relative;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        text-align: left;
    }

    ${({ bullet }) =>
        bullet &&
        css`
            & li:before {
                background: url(${bullet}) center center no-repeat;
                content: '';
                display: block;
                margin-right: 15px;
                padding: 4px;
            }
        `}

    ${({ bulletMd }) =>
        bulletMd &&
        media.md`
        & li:before {
            background: url(${bulletMd}) center center no-repeat;
            content: '';
            display: block;
            margin-right: 15px;
            padding: 3px;
        }
    `}}

    ${media.md`
      flex-direction: row;
      gap: ${(props) => (has(props, 'gapBetweenListsMd') ? rem(props.gapBetweenListsMd) : rem(40))};
    `};
`;

export default ListWrapper;
