import { typographyUtils } from 'baseline-ui/typography';
import { has } from 'lodash';
import styled from 'styled-components';
import siteBaseTheme from 'styles/siteBaseTheme';

const Separator = styled.div`
    border-style: solid;
    border-color: ${({color}) =>
        color
            ? `${siteBaseTheme.base.grays[color]}`
            : `${siteBaseTheme.base.colors.primary}`
     };
    border-width: ${({borderWidth}) =>
        borderWidth
            ? `${borderWidth}px`
            : '6px'
    };
    margin-top: ${({margin}) => {
        if(has(margin, 'top')) {
            return typographyUtils.processSize(margin.top)
        }

        return 0
    }};
    margin-right: ${({margin}) => {
        if(has(margin, 'right')) {
            return typographyUtils.processSize(margin.right)
        }

        return 0
    }};
    margin-bottom: ${({margin}) => {
        if(has(margin, 'bottom')) {
            return typographyUtils.processSize(margin.bottom)
        }
        
        return 0
    }};
    margin-left: ${({margin}) => {
        if(has(margin, 'left')) {
            return typographyUtils.processSize(margin.left)
        }
    
        return 0
    }};
`;

export default Separator;