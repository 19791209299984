import { rem, transparentize } from 'polished';
import styled from 'styled-components';


/**
 * Entity Picker Image container.
 */
export const EntityPickerImageContainer = styled.div`
    display: inline-flex;
    background-color: ${({ theme }) => transparentize(0.6, theme.base.colors.light)};
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.lg)};
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    height: 5rem;
    width: 5rem;
    grid-area: 1 / 1 / 2 / 2;
`;

/**
 * Entity Picker Data container.
 */
export const EntityPickerData = styled.div`
    // Within parent grid.
    grid-area: 1 / 2 / 2 / 3;

    // Setup grid for nested elements.
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
`;

/**
 * Entity Picker Selection. Holds EntityPickerOption components.
 */
export const EntityPickerSelect = styled.div`
    display: ${({ hidden }) => (hidden ? 'none' : 'block')};
    height: 16rem;
    padding: 0.5rem 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    border: thin solid ${({ theme }) => theme.form.input.borderColor};
`;


/**
 * Entity Picker Option Item. 
 */
export const EntityPickerDetailsItem = styled.label`
    display: grid;
    grid-template-columns: 1fr ${(props) => props.hasClearButton ? '5fr' : '4fr'};
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    width: 100%;
    height: 6rem;
    padding: 0.2rem;
    margin: 0.2rem;
    overflow: hidden;
    border-radius: 0.2rem;
    border: thin solid transparent;
`;

/**
 * Entity Picker Option Item. 
 */
export const EntityPickerOptionItem = styled.label`
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; 
    width: 100%;
    height: 6rem;
    padding: 0.2rem;
    margin: 0.2rem;
    overflow: hidden;
    border-radius: 0.2rem;
    cursor: pointer;
    user-select: none;
    border: ${({ isSelected, theme }) => (isSelected ? `thin solid ${theme.form.input.light.borderColor}` : 'thin solid transparent')}};
`;

/**
 * Entity Picker Button Cell.
 */
export const EntityPickerButtonCell = styled.div`
    padding-top: 1rem;
    grid-area: 1 / 5 / 2 / 6;
    overflow: hidden;
`;