const siteBaseTheme = {
    base: {
        colors: {
            primary: '#F15A24',
            secondary: '#482655',
            primaryMuted: '#FEEEE8',
            info: '#0855FF',
            infoLight: '#E5EDFF',
            light: '#ffffff',
            dark: '#050628',
            success: '#2AD00C',
            warning: '#d39a11',
            blueLight: '#54C7F5',
            transparent: 'transparent'
        },
        grays: {
            gray: '#808080',
            grayLight: '#a6a6a6',
            grayLighter: '#cccccc',
            grayLightest: '#e5e5e5',
            grayFaint: '#F9F9F9',
            grayDark: '#595959',
            grayDarker: '#333333',
            grayDarkest: '#262626',
        },
        borderRadius: {
            lg: 16,
        },
        scrollbar: {
            onBody: true,
        },
    },
};

export default siteBaseTheme;
