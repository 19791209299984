import { Desktop, MobileTablet } from 'baseline-ui/layout';
import Label from 'components/Label';
import { FormattedMessage } from 'react-intl';

const AboutText = () => {
    return (
        <>
            <MobileTablet>
                <FormattedMessage 
                    id="auth.about.hero.content"
                    values={{
                        wrapper: (text) => <Label display="block" skin="secondary" lineHeight={30} fontSize={20} maxWidth={806}>{text}</Label>,
                        orange: (text) => <Label skin="primary">{text}</Label>,
                        orangeBold: (text) => <Label skin="primary" fontWeight={700}>{text}</Label>,
                        title: (text) => <Label 
                                            display="inline-block" 
                                            fontWeight={700}
                                            margin={{
                                                bottom: 10,
                                                top: 36
                                            }}
                                        >{text}</Label>,
                        link: (text) => <Label 
                                            display="inline-block" 
                                            margin={{
                                                bottom: 10,
                                            }}
                                            skin="primary"
                                            border={{
                                                bottom: {
                                                    size: 2,
                                                    style: "solid",
                                                    color: "primary"
                                                }
                                            }}
                                        >{text}</Label>,
                        br: () => <br />
                    }}
                />
            </MobileTablet>
            <Desktop>
                <FormattedMessage 
                        id="auth.about.hero.content"
                        values={{
                            wrapper: (text) => <Label display="block" skin="secondary" lineHeight={35} fontSize={20} maxWidth={806}>{text}</Label>,
                            orange: (text) => <Label skin="primary">{text}</Label>,
                            orangeBold: (text) => <Label skin="primary" fontWeight={600}>{text}</Label>,
                            title: (text) => <Label 
                                                display="inline-block" 
                                                fontWeight={700}
                                                margin={{
                                                    bottom: 10,
                                                    top: 36
                                                }}
                                            >{text}</Label>,
                            link: (text) => <Label 
                                                display="inline-block" 
                                                margin={{
                                                    bottom: 10,
                                                }}
                                                skin="primary"
                                                border={{
                                                    bottom: {
                                                        size: 2,
                                                        style: "solid",
                                                        color: "primary"
                                                    }
                                                }}
                                            >{text}</Label>,
                            br: () => <br />
                        }}
                    />
            </Desktop>
        </>
    );
};

export default AboutText;
