import React, { useContext } from 'react';
import { GaIcon } from 'baseline-ui/icon';
import { ThemeContext } from 'styled-components';
import FavePagePreviewFavesContainer from './FavePagePreviewFavesContainer';
import FavePagePreviewFavesButtonsContainer from './FavePagePreviewFavesButtonsContainer';
import FavePagePreviewFaveButton from './FavePagePreviewFaveButton';
import FavePagePreviewFavesButton from './FavePagePreviewFavesButton';

const FavePagePreviewFaves = () => {
    const theme = useContext(ThemeContext);
    return (
        <FavePagePreviewFavesContainer>
            <FavePagePreviewFavesButton skin="primaryMuted">Total Faves</FavePagePreviewFavesButton>
            <FavePagePreviewFavesButtonsContainer>
            <FavePagePreviewFaveButton>
                <GaIcon
                    icon="save"
                    size={28}
                    color={theme.typography.colors.primary}
                />
            </FavePagePreviewFaveButton>
            <FavePagePreviewFaveButton>
                <GaIcon
                    icon="favepage-heart-filled"
                    size={28}
                    color={theme.typography.colors.primary}
                />
            </FavePagePreviewFaveButton>
            </FavePagePreviewFavesButtonsContainer>
        </FavePagePreviewFavesContainer>
    );
};

export default FavePagePreviewFaves;
