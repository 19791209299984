import { rem } from 'polished';
import styled from 'styled-components';

const FavePagePreviewLinksContainer = styled.div`
    padding-top: ${rem(4)};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;

export default FavePagePreviewLinksContainer;
